import {
  SEARCH_PROCESS,
  REGISTER_PROCESS,
  GET_PROCESS,
  ADD_SUBSCRIBER,
  GET_SINGLE_PROCESS,
  GET_PROCESS_TASK,
  GET_PROCESS_USER,
  GET_PROCESS_GAITS,
  ADD_GAITS_RESPONSE,
  GET_PROSESS_STATUS,
  CHANGE_PROSESS_STATUS,
  FILTER_PROCESS,
  PROCESS_COURT_COST,
  DELETE_PROCESS,
  UPLOAD_PROCESS,
} from "../url";
import { AfterAuthApi } from "..";

export const searchProcessList = submitData => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(SEARCH_PROCESS, "post", submitData)
      .then(res => {
        resolve(res.data);
      })
      .catch(reject);
  });
};

export const registerProcess = submitData => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(REGISTER_PROCESS, "post", submitData)
      .then(res => {
        resolve(res.data);
      })
      .catch(reject);
  });
};

export const getProcess = () => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(GET_PROCESS, "post")
      .then(res => {
        resolve(res.data);
      })
      .catch(reject);
  });
};

export const addSubscriber = submitData => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(ADD_SUBSCRIBER, "post", submitData)
      .then(res => {
        resolve(res.data);
      })
      .catch(reject);
  });
};

export const getSingleProcess = submitData => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(GET_SINGLE_PROCESS, "post", submitData)
      .then(res => {
        resolve(res.data);
      })
      .catch(reject);
  });
};

export const getProcessTask = submitData => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(GET_PROCESS_TASK, "post", submitData)
      .then(res => {
        resolve(res.data);
      })
      .catch(reject);
  });
};

export const getInviteProcessUser = submitData => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(GET_PROCESS_USER, "post", submitData)
      .then(res => {
        resolve(res.data);
      })
      .catch(reject);
  });
};

export const getProcessGaits = submitData => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(GET_PROCESS_GAITS, "post", submitData)
      .then(res => {
        resolve(res.data);
      })
      .catch(reject);
  });
};

export const addGaitsResponse = submitData => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(ADD_GAITS_RESPONSE, "post", submitData)
      .then(res => {
        resolve(res.data);
      })
      .catch(reject);
  });
};

export const getProcessStatus = () => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(GET_PROSESS_STATUS, "post")
      .then(res => {
        resolve(res.data);
      })
      .catch(reject);
  });
};

export const changeProcessStatus = submitData => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(CHANGE_PROSESS_STATUS, "post", submitData)
      .then(res => {
        resolve(res.data);
      })
      .catch(reject);
  });
};

export const filterProcess = submitData => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(FILTER_PROCESS, "post", submitData)
      .then(res => {
        resolve(res.data);
      })
      .catch(reject);
  });
};

export const processCourtCost = submitData => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(PROCESS_COURT_COST, "post", submitData)
      .then(res => {
        resolve(res.data);
      })
      .catch(reject);
  });
};

export const deleteProcess = submitData => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(DELETE_PROCESS, "post", submitData)
      .then(res => {
        resolve(res.data);
      })
      .catch(reject);
  });
};

export const uploadProcess = submitData => {
  const formData = new FormData();
  formData.append("xmlFile", submitData.xmlFile);
  return new Promise((resolve, reject) => {
    AfterAuthApi(UPLOAD_PROCESS, "post", formData)
      .then(res => {
        resolve(res.data);
      })
      .catch(reject);
  });
};
