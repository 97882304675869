import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import LockIcon from "@mui/icons-material/Lock";

import styled from "@emotion/styled";

import { useNavigate } from "react-router-dom";

const Img = styled("img")({
  // margin: 'auto',
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
});

const Error = () => {

  const navigate = useNavigate();

  return (
    <Container component="main" maxWidth="md" sx={{ mt: 5, backgroundColor: "#fff" }}>
      <Box
        sx={{
          // marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}>
        <Img alt="complex" src="/assets/images/inova_juris_logo.png" sx={{ height: 150 }} />
        <Box component="form" sx={{ mt: 1, display: "flex", justifyContent: "center", alignItems: "center" }}>
          <LockIcon sx={{ fontSize: "2rem", padding: "0rem 1rem 0rem 0rem" }} />
          <h1 style={{ color: "grey" }}>você não tem permissão</h1>
        </Box>
        <br />
        <Box component="form">
          <h3 style={{ color: "grey" }}>Seu plano atual expirou; adquira um novo plano.</h3>
        </Box>
        <div
          className="wp-block-buttons is-content-justification-center is-layout-flex"
          style={{ display: "flex", alignItems: "center" }}>
          <div className="wp-block-button wide-button-large">
            <button
              className="wp-block-button__link has-background"
              // href="https://app.inovajuris.com.br/novocadastro/plano=plano1"
              style={{
                backgroundColor: "#542d78",
                color: "#fff",
                border: 0,
                marginBottom: 50
              }}
              onClick={() => {
                navigate('/plan')
              }}
              target="_blank"
              rel="noreferrer noopener"
            >
              Compre um plano
            </button>
          </div>
        </div>
      </Box>
    </Container>
  );
};
export default Error;
