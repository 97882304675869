import React, { useState, useEffect } from "react";
import { BasicPage } from "../components/BasicPage";
import Home from "@mui/icons-material/Home";
import HomeCard from "../components/HomeCard";
import CustomCard from "../components/Inputs/Card";
import ProgressCard from "../components/ProgressCard";
import { getAdvocateTask } from "../helper/API/task";
import { getDashboardGoals } from "../helper/API/dashboard";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import * as profileAction from "../redux/profile/action";
import Loader from "../components/Loader";

const HomePage = ({ permission, loginData, getProfile }) => {
  //  const currentDate = moment().format("YYYY-MM-DD");
  const [cardLoading, setCardLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [taskList, setTaskList] = useState([]);
  const [dashboardGoals, setDashboardGoals] = useState();

  useEffect(() => {
    setLoading(true);
    const submitData = { date: null };
    getAdvocateTask(submitData).then(res => {
      // console.log("res dashboard", res);
      if (res.success) {
        setTaskList(res.data.getTask);
        setLoading(false);
      } else {
        setLoading(false);
        // toast.error(res.message);
      }
    });
    // console.log("permission", permission);
    getDashboardGoals().then(res => {
      // console.log("res dashbopard goals", res);
      if (res.success) {
        setDashboardGoals(res.data);
      } else {
        // toast.error(res.message);
      }
    });

    if (loginData) {
      getProfile().then(res => {
        // console.log("res :: profile", res);
      });
    }
  }, []);
  // console.log("cardLoading", cardLoading);
  return (
    <div style={{ background: "#F8F5FA" }}>
      {!cardLoading && <HomeCard setCardLoading={setCardLoading} />}

      {loading ? <Loader /> : <CustomCard taskList={taskList} />}
      {permission?.isOwner && permission?.financialMgt ? <ProgressCard dashboardGoals={dashboardGoals} /> : ""}
    </div>
  );
};

const mapStateToProps = state => {
  // console.log("state", state);
  return {
    permission: state?.getProfileReducer?.profile,
    loginData: state?.loginUsers?.isLoggedIn,
  };
};

const mapDispatchToProp = dispatch => {
  return {
    getProfile: () => dispatch(profileAction.getUserProfile()),
  };
};
export default connect(mapStateToProps, mapDispatchToProp)(HomePage);
