import { Grid, Typography } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import React from "react";
import AttachmentCard from "./AttachmentCard";

const ChatDescriptionCard = ({ chatHistoryData }) => {
  // console.log("chatHistoryData", chatHistoryData);
  // console.log("chatHistoryData", chatHistoryData.type);
  return (
    <div>
      <Grid container sx={{ marginBottom: "2rem", display: "flex", alignItems: "center" }}>
        <Grid item xs={3} sm={1} md={1}>
          <img
            src={chatHistoryData?.avatar ? chatHistoryData?.avatar : "/temp/user.png"}
            height="60px"
            width="60px"
            style={{ borderRadius: "50%" }}
          />
        </Grid>
        <Grid item xs={9} sm={11} md={11} sx={{ position: "relative", left: "-2%" }}>
          <Grid container>
            <Grid item xs={6} sm={6} md={6} sx={{ color: "#000000", fontWeight: 500, fontFamily: "Poppins" }}>
              {chatHistoryData?.from}
            </Grid>
            <Grid item xs={6} sm={6} md={6} sx={{ textAlign: "end" }}>
              {chatHistoryData?.newDate}
            </Grid>
            <Grid item xs={12} sm={12} md={12} sx={{ paddingRight: "1rem", marginTop: "1rem", fontWeight: 400, fontFamily: "Poppins" }}>
              {chatHistoryData.type === "attachment" ? (
                // <a href={chatHistoryData.message} target="_blank">
                //   {chatHistoryData.message}
                // </a>
                // <AttachmentCard />
                <Grid container>
                  {/* <Grid item xs={2} md={1} sx={{ display: "flex", justifyContent: "start", alignItems: "center" }}>
                  </Grid> */}
                  <Grid item xs={7} md={11} sx={{ textAlign: "start", display: "flex", alignItems: "center" }}>
                    <img src="/assets/images/file.png" height="40px" width="30px" style={{ marginRight: "1rem" }} />
                    <Typography sx={{ fontWeight: 400, fontFamily: "Poppins" }}>{chatHistoryData?.fileName}</Typography>
                    {/* <Typography sx={{ fontWeight: 400, fontFamily: "Poppins", color: "#6F737F" }}>{"attachment.ext"}</Typography> */}
                  </Grid>
                  <Grid item xs={3} md={1} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <a href={chatHistoryData.message} target="_blank">
                      <div
                        style={{
                          backgroundColor: "#8E91F4",
                          display: "flex",
                          justifyContent: "center",
                          width: "3rem",
                          padding: "0.5rem 0rem",
                          borderRadius: "50%",
                        }}>
                        {/* <img src="/assets/images/download.png" height={35} /> */}
                        <FileDownloadIcon sx={{ color: "#fff" }} />
                      </div>
                    </a>
                  </Grid>
                </Grid>
              ) : (
                chatHistoryData?.message
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default ChatDescriptionCard;
