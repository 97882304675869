import { GET_ADVOCATE_LIST, ADVOCATE_PROCESSING, STOP_ADVOCATE_PROCESSING } from "./type";

const defaultReducer = {
  advocateList: [],
  processing: false,
};

const advocateListReducer = (state = defaultReducer, action) => {
  switch (action.type) {
    case GET_ADVOCATE_LIST:
      return {
        ...state,
        processing: false,

        advocateList: action.payload,
      };

    case ADVOCATE_PROCESSING:
      return {
        ...state,
        processing: true,
      };

    case STOP_ADVOCATE_PROCESSING:
      return {
        ...state,
        processing: false,
      };

    default:
      return state;
  }
};

export default advocateListReducer;
