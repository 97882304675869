import { GET_GAITS_LIST, GAITS_PROCESSING, STOP_GAITS_PROCESSING, GET_GAITS_ADMENTOS } from "./type";

const defaultReducer = {
  gaitsList: [],
  admentos: [],
  processing: false,
};

const gaitsListReducer = (state = defaultReducer, action) => {
  switch (action.type) {
    case GET_GAITS_LIST:
      return {
        ...state,
        processing: false,
        gaitsList: action.payload,
      };

    case GAITS_PROCESSING:
      return {
        ...state,
        processing: true,
      };
    case GET_GAITS_ADMENTOS:
      return {
        ...state,
        admentos: action.payload,
        processing: false,
      };

    case STOP_GAITS_PROCESSING:
      return {
        ...state,
        processing: false,
      };

    default:
      return state;
  }
};

export default gaitsListReducer;
