import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    // "& .MuiBox-root": {
    "& .MuiPaper-root": {
      // border: "5px solid red",
      backgroundColor: "#F8F5FA",
    },
    // },
    "& .MuiGrid-root": {
      "& .MuiFormControl-root": {
        "& .MuiFormLabel-root": {
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: 600,
          fontSize: "16px",
          lineHeight: "24px",
          color: "#333333",
        },
      },
    },
  },

  card: {
    // marginBottom: "1rem",
    boxShadow: "unset !important",
    // borderRadius: "14px",
    border: "none",
    borderRadius: "20px",
    backgroundColor: "#F8F5FA",
    display: "flex",
  },
  content: {
    borderRadius: "20px",
    flexGrow: 5,
  },
});

const RecordFound = ({ label }) => {
  const classes = useStyles();

  return (
    <div>
      <Box sx={{ mt: 3 }} className={classes.root} px={8}>
        <Card variant="elevation" className={classes.card}>
          <CardContent className={classes.content} sx={{ backgroundColor: "#fff", borderRadius: "20px" }}>
            <Box p={0} display={"flex"} justifyContent={"center"} alignItems={"center"} color={"#7a7a7a"}>
              <h2>{label}</h2>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

export default RecordFound;
