import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { toast } from "react-toastify";
import { deleteAdvocateTask } from "../../helper/API/task";

const DeleteTarefaModal = ({ setRefresh, refresh, open, handleClose, deleteId }) => {
  const boxSX = {
    border: "1px solid #734699",
    paddingX: "2rem",
    background: "#734699",
    color: "#fff",
    "&:hover": {
      color: "#fff",
      backgroundColor: "#734699",
    },
  };
  // console.log("deleteId", deleteId);
  const deleteTask = () => {
    const submitData = { id: deleteId };
    deleteAdvocateTask(submitData).then(res => {
      // console.log("res", res);
      if (res.success) {
        setRefresh(refresh + 1);
        handleClose();
        toast.success(res.message);
      } else {
        toast.error(res.message);
      }
    });
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title" sx={{ color: "#734699", fontWeight: 500, fontFamily: "Poppins", fontSize: "28px" }}>
          Excluir item
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            sx={{ color: "#27272B", fontWeight: 300, fontFamily: "Poppins", fontSize: "18px" }}>
            Tem certeza que deseja excluir o item selecionado?{" "}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "space-around", mb: 4 }}>
          <Button onClick={handleClose} sx={{ border: "1px solid #734699", px: "2rem" }}>
            Cancelar
          </Button>
          <Button onClick={deleteTask} autoFocus sx={boxSX}>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default DeleteTarefaModal;
