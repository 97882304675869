import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Cards from "../components/Despesas/Cards";
import WeekCalender from "../components/Despesas/WeekCalender";
import DespesasCard from "../components/Despesas/DespesasCard";
import FinanceiroDespesasChart from "../components/Charts/FinanceiroDespesasChart";
import { getChartData, getChartCards, getChartGraphicCardBottom } from "../helper/API/paymentTaskByAdvocate";
import Loader from "../components/Loader";
import RecordFound from "../components/RecordFound/RecordFound";
import PlusBottom from "../components/PlusBottom";
import { useNavigate } from "react-router-dom";

const Despesas = ({ cardData }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectVal, setSelectVal] = React.useState("expense");
  const [chartData, setChartData] = React.useState([]);
  const [cards, setCards] = React.useState([]);
  const [bottomCards, setBottomCards] = React.useState([]);
  const [loading, setloading] = React.useState(false);

  const navigate = useNavigate();
  const [val, setVal] = React.useState("income");

  const open = Boolean(anchorEl);
  const handleClick = event => {
    // console.log("event.currentTarget", event.currentTarget);
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    setloading(true);
    const submitData = { type: val };
    getChartData(submitData).then(res => {
      // console.log("res", res);
      if (res.success) {
        setloading(false);
        setChartData(res.data);
      } else {
        setChartData([]);
        setloading(false);
      }
    });

    getChartGraphicCardBottom(submitData).then(res => {
      // console.log("res", res);
      if (res.success) {
        // setloading(false);
        setBottomCards(res.data);
      } else {
        // setloading(false);
      }
    });
  }, [val]);
  useEffect(() => {
    setloading(true);
    getChartCards().then(res => {
      if (res.success) {
        setCards(res.data);
        setloading(false);
      } else {
        setloading(false);
      }
    });
  }, [val]);

  // console.log("selectVal", selectVal);

  const handleSelectChange = event => {
    setVal(event.target.value);
  };

  const handlePlusButton = () => {
    navigate("/home/financeiro/adicionar", { state: { transaction: val } });
  };
  // console.log("chartData", chartData);

  return (
    <Box sx={{ flexGrow: 1, background: "#F8F5FA" }} px={8} py={4}>
      <PlusBottom url="/home/financeiro/adicionar" handleClick={handlePlusButton} />

      <>
        {loading ? (
          <Loader />
        ) : cards ? (
          <Cards
            cards={cards}
            cardData={cardData}
            value={val}
            handleChange={handleSelectChange}
            statusProcess={[
              { label: "Entradas", value: "income" },
              { label: "Despesas", value: "expense" },
            ]}
          />
        ) : (
          <RecordFound label={"Nenhum registro foi encontrado"} />
        )}
        {loading ? (
          <Loader />
        ) : chartData.length ? (
          <FinanceiroDespesasChart chartData={chartData} />
        ) : (
          <RecordFound label={"Nenhum dado disponível"} />
        )}
        {loading ? (
          <Loader />
        ) : bottomCards.length ? (
          <DespesasCard bottomCards={bottomCards} selectVal={val} />
        ) : (
          <RecordFound label={"Nenhum registro foi encontrado"} />
        )}
      </>
    </Box>
  );
};

export default Despesas;
