import React from "react";
import { Paper, Button, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import CancelIcon from "@mui/icons-material/Cancel";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Slider from "react-slick";

import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import RecordFound from "../RecordFound/RecordFound";

const useStyles = makeStyles({
  mainContainer: {
    display: "flex !important",
  },
  relative: {
    position: "relative",
    // marginRight: 40,
  },
  card: {
    // marginTop: 28,
    // marginX: 15,

    boxShadow: "0px 8.71526px 17.4305px rgba(0, 0, 0, 0.32) !important",
    borderRadius: "17.4px !important",
    // minWidth: "350px !important",
    minHeight: 150,
    position: "relative",
    margin: "25px !important",
  },

  title: {
    color: "#000",
    fontSize: 18,
    fontWeight: "normal",
    fontFamily: "Poppins",
  },
  desc: {
    margin: "15px 5px !important",
    color: "#000",
    textAlign: "start !important",
  },
  time: {
    margin: "15px 5px !important",
    color: "#2FD757",
    textAlign: "start !important",
  },
  arrow: {
    position: "absolute",
    borderRadius: "50%",
    top: "40%",
    right: -15,
    padding: "5px",
  },
});

const CalendarTask = ({ taskData }) => {
  const classes = useStyles();
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    // centerPadding: "60px",
    slidesToShow: taskData?.length <= 2 ? 1 : 3,
    speed: 500,
    arrow: false,
  };
  // console.log("Calendar taskData", taskData);
  return (
    <div>
      <Slider {...settings} arrow={false} style={{ overflow: "hidden" }}>
        {taskData?.length ? (
          taskData?.map((obj, index) => (
            <div>
              <Card
                variant="elevation"
                className={classes.card}
                sx={{
                  background: "#fff",
                  // width: "200px",
                }}>
                <CardContent>
                  <div style={{ height: "2rem", width: "2rem", backgroundColor: obj?.category?.color, borderRadius: "50%" }}></div>
                  <div>
                    {/* <Typography variant="" className={classes.title}> */}
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                      <div>{obj.title}</div>
                    </div>
                    {/* </Typography> */}
                    <Typography variant="body2" className={classes.desc}>
                      {obj.description}
                    </Typography>
                    <Typography variant="body2" className={classes.time}>
                      {obj.startTime}
                    </Typography>
                  </div>
                </CardContent>
              </Card>
            </div>
          ))
        ) : (
          <RecordFound label="Nenhuma tarefa encontrada" />
        )}
      </Slider>
    </div>
  );
};

export default CalendarTask;
