export const TRANSLATIONS_PR = {
  title: "Willkommen zu react und react-i18next",
  description: {
    part1: "Um loszulegen, ändere  und speichere um neuzuladen.",
    part2: "Wechsle die Sprache zwischen deutsch und englisch mit Hilfe der beiden Schalter.",
  },
  login: "Conecte-se",
  forgotPassword: "Esqueceu sua senha?",
  signInGoogle: "Faça login no Google",
  viewMore: "Veja mais",
  financialGoal: "Meta Financeira",
  targetClients: "Meta Clientes",
  clients: "Clientes",
  notes: "Notas",
};
