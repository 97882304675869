import * as React from "react";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@material-ui/core/styles";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import CarousalCard from "./Carousal/CarousalCard";

const useStyles = makeStyles({
  mainContainer: {
    display: "flex !important",
  },
  relative: {
    position: "relative",
    // marginRight: 40,
  },
  card: {
    marginTop: 28,
    background: "linear-gradient(194deg, #9882F6 48%, #0F153B 104%)",
    boxShadow: "0px 8.71526px 17.4305px rgba(0, 0, 0, 0.32) !important",
    borderRadius: "17.4px !important",
    // minWidth: "350px !important",
    minHeight: 150,
    position: "relative",
  },
  card1: {
    marginTop: 28,
    background: "linear-gradient(149deg, #E96047 -19.6%, #EB57A6 44.21%, #4A3260 108.02%)",
    boxShadow: "0px 8.71526px 17.4305px rgba(0, 0, 0, 0.32) !important",
    borderRadius: "17.4px !important",
    // minWidth: "350px !important",
    minHeight: 150,
    position: "relative",
  },
  card2: {
    marginTop: 28,
    background: "linear-gradient(158deg,#F4A8A8 0%, #F4738C 91%, rgba(244, 115, 140, 0.7) 100%)",
    borderRadius: "17.4px !important",
    // minWidth: "350px !important",
    minHeight: 150,
    position: "relative",
    boxShadow: "0px 8.71526px 17.4305px rgba(0, 0, 0, 0.32) !important",
  },
  title: {
    color: "#fff",
    marginLeft: 20,
    fontSize: 18,
    fontWeight: 700,
  },
  desc: {
    margin: "15px 5px !important",
    color: "#fff",
  },
  arrow: {
    position: "absolute",
    borderRadius: "50%",
    top: "40%",
    right: -15,
    padding: "5px",
  },
});

const HomeCard = ({ setCardLoading }) => {
  const classes = useStyles();

  return (
    <>
      <Box sx={{ flexGrow: 1 }} px={8} py={4}>
        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 2, sm: 8, md: 12 }}>
          <Grid item xs={12} sm={12} md={12}>
            <div className={classes.relative}>
              <CarousalCard setCardLoading={setCardLoading} />
            </div>
          </Grid>
          {/* <Grid item xs={2} sm={4} md={4}>
            <div className={classes.relative}>
              <Card variant="elevation" className={classes.card1}>
                <CardContent>
                  <Typography variant="body1" className={classes.title}>
                    Mensagem Equipe:
                  </Typography>
                  <Typography variant="body2" className={classes.desc}>
                    Adriana: Bom dia dr. Fernando! Qual a estimativa para o andamento do processo?
                  </Typography>
                </CardContent>
              </Card>
              <div className={classes.arrow} style={{ background: "#FFCBCB" }}>
                <ArrowForwardIosIcon style={{ color: "#fff", fontSize: 18 }} />
              </div>
            </div>
          </Grid>
          <Grid item xs={2} sm={4} md={4}>
            <div className={classes.relative}>
              <Card variant="elevation" className={classes.card2}>
                <CardContent>
                  <Typography variant="body1" className={classes.title}>
                    Mensagem Cliente:
                  </Typography>
                  <Typography variant="body2" className={classes.desc}>
                    Adriana: Bom dia dr. Fernando! Qual a estimativa para o andamento do processo?
                  </Typography>
                </CardContent>
              </Card>
              <div className={classes.arrow} style={{ background: "#F26472" }}>
                <ArrowForwardIosIcon style={{ color: "#fff", fontSize: 18 }} />
              </div>
            </div>
          </Grid> */}
        </Grid>
      </Box>
    </>
  );
};

export default HomeCard;
