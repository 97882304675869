import { Button } from "@mui/material";
import React from "react";

const StyledButton = ({ type, fullWidth, variant, btnText, sx, disable, className }) => {
  return (
    <Button type={type} fullWidth={fullWidth} variant={variant} sx={sx} disable={disable} className={className}>
      {btnText}
    </Button>
  );
};

export default StyledButton;
