import { AfterAuthApi } from "../../helper";
import { GET_PROFILE_SUCCESSFUL, PROFILE_PROCESSING, STOP_PROFILE_PROCESSING } from "./type";
import { GET_SINGLE_OFFICE_DETAILS } from "../../helper/url";
import { getAdvocateDetail } from "../../helper/API/advocate";

export const getProfileData = payload => dispatch => {
  return new Promise((resolve, reject) => {
    const submitData = { id: payload };
    dispatch({ type: PROFILE_PROCESSING });
    getAdvocateDetail(submitData)
      .then(profile => {
        // console.log("profile advocate", profile);
        dispatch({
          type: GET_PROFILE_SUCCESSFUL,
          payload: profile?.data?.data,
        });
        resolve(profile.data);
      })
      .catch(error => {
        dispatch({ type: STOP_PROFILE_PROCESSING });
        // console.log("error in advocate profile action", error.message);
      });
  });
};
