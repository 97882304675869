import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import { Doughnut } from "react-chartjs-2";
import Typography from "@mui/material/Typography";
const useStyles = makeStyles({
  title: {
    color: "#464A53",
    fontFamily: "Poppins !important",
    fontWeight: "700 !important",
    fontSize: "16px !important",
    margin: "0 !important",
    display: "flex",
    justifyContent: "center",
  },
  subtitle: {
    fontFamily: "Poppins !important",
    fontWeight: "700 !important",
    fontSize: "24px !important",
    margin: "0 !important",
    display: "flex",
    justifyContent: "center",
  },
  orange: {
    color: "#FFC107 !important",
  },
  purple: {
    color: "#5031A9 !important",
  },
  green: {
    color: "#71D875 !important",
  },
  red: {
    color: "red !important",
  },
});
const PieChartComponent = ({ data, options, plugins, subtitle, amount, amountColor }) => {
  const classes = useStyles();
  // const color = amountColor === "purple" ? classes.purple : amountColor === "orange" ? classes.orange : classes.green;
  let color;
  if (amountColor === "purple") {
    color = classes.purple;
  } else if (amountColor === "orange") {
    color = classes.orange;
  } else if (amountColor === "red") {
    color = classes.red;
  } else {
    color = classes.green;
  }
  return (
    <div style={{ width: "200px" }}>
      <Doughnut data={data} options={options} plugins={[plugins]} />
      <div>
        <Typography variant="h6" noWrap className={classes.title} component="div">
          {subtitle}
        </Typography>
        <Typography variant="h5" noWrap className={classes.subtitle} component="div">
          <span className={color}>{amount}</span>
        </Typography>
      </div>
    </div>
  );
};

export default PieChartComponent;
