import * as React from "react";
import TextField from "@mui/material/TextField";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { toast } from "react-toastify";
import { FormLabel, Grid, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { addTaskCategory } from "../../helper/API/task";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";

const AddCategoryModal = ({ open, onHide, setRefresh, refresh, categoryColor }) => {
  const [values, setValues] = React.useState({
    label: "",
    color: "",
  });

  const handleFormChange = e => {
    // console.log("event target", e.target.value);
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = () => {
    // console.log("values", values);
    addTaskCategory(values).then(res => {
      // console.log("res", res);
      if (res.success) {
        setRefresh(refresh + 1);
        toast.success(res.message);
        onHide();
      } else {
        toast.error(res.message);
      }
    });
  };

  return (
    <div>
      <Dialog fullWidth maxWidth={"sm"} open={open} onClose={onHide}>
        <DialogTitle>Adicionar categoria</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item sm={12} md={12} lg={12}>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                name="label"
                label="Etiqueta"
                type="text"
                fullWidth
                variant="standard"
                onChange={handleFormChange}
              />
            </Grid>
            <Grid item sm={12} md={12} lg={12}>
              <FormControl variant="standard" fullWidth>
                <InputLabel id="demo-simple-select-standard-label">Categoria</InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  // value={age}
                  name="color"
                  onChange={handleFormChange}
                  label="Categoria">
                  {categoryColor?.map((obj, i) => (
                    <MenuItem value={obj.label}>
                      {/* <Typography>{obj?.label}</Typography> */}
                      <Typography sx={{ height: "20px", width: "160px", borderRadius: "", mx: 1, backgroundColor: obj.label }}></Typography>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onHide}>Cancelar</Button>
          <Button onClick={handleSubmit}>Salvar</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddCategoryModal;
