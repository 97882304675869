import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  FormLabel,
  MenuItem,
  Switch,
  TextareaAutosize,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { experimentalStyled as styled } from "@mui/material/styles";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation, useNavigate } from "react-router-dom";
import { Grid, TextField } from "@mui/material";
import SwitchUnstyled, { switchUnstyledClasses } from "@mui/base/SwitchUnstyled";
import { FormControl, InputAdornment, OutlinedInput } from "@mui/material";

import StyledOutlinedInput from "../components/Inputs/StyledOutlinedInput";
import StyledButton from "../components/StyledButton";

import { withStyles } from "@material-ui/core/styles";
import AddCategoryModal from "../components/Tarefa/AddCategoryModal";
import { addAdvocateTask, editAdvocateTask, getTaskCategory, getTaskCategoryColor } from "../helper/API/task";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import DatePickerTranslated from "../components/DatePickerTranslated";
const meeting = [
  {
    value: "Skype",
    label: "Skype",
  },
  {
    value: "Google Meet",
    label: "GoogleMeet",
  },
];
const meetingTime = [
  {
    value: "Reuniao",
    label: "Reuniao",
  },
  {
    value: "Reuniao1",
    label: "Reuniao1",
  },
];

const blue = {
  500: "#5031A9",
};

const grey = {
  400: "#BFC7CF",
  500: "#AAB4BE",
  600: "#6F7E8C",
};

const Root = styled("span")(
  ({ theme }) => `
    font-size: 0;
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
    margin: 10px;
    cursor: pointer;
  
    &.${switchUnstyledClasses.disabled} {
      opacity: 0.4;
      cursor: not-allowed;
    }
  
    & .${switchUnstyledClasses.track} {
      background: ${theme.palette.mode === "dark" ? grey[600] : grey[400]};
      border-radius: 10px;
      display: block;
      height: 100%;
      width: 100%;
      position: absolute;
    }
  
    & .${switchUnstyledClasses.thumb} {
      display: block;
      width: 14px;
      height: 14px;
      top: 3px;
      left: 3px;
      border-radius: 16px;
      background-color: #fff;
      position: relative;
      transition: all 200ms ease;
    }
  
    &.${switchUnstyledClasses.focusVisible} .${switchUnstyledClasses.thumb} {
      background-color: ${grey[500]};
      box-shadow: 0 0 1px 8px rgba(0, 0, 0, 0.25);
    }
  
    &.${switchUnstyledClasses.checked} {
      .${switchUnstyledClasses.thumb} {
        left: 22px;
        top: 3px;
        background-color: #fff;
      }
  
      .${switchUnstyledClasses.track} {
        background: ${blue[500]};
      }
    }
  
    & .${switchUnstyledClasses.input} {
      cursor: inherit;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      opacity: 0;
      z-index: 1;
      margin: 0;
    }
    `,
);

const Img = styled("img")({
  display: "block",
  //   maxWidth: "40%",
  //   maxHeight: "100%",
});

const useStyles = makeStyles({
  root: {
    "& .MuiGrid-root": {
      // border: "2px solid red",
      "& .MuiButtonBase-root": {
        "&:hover": {
          backgroundColor: "transparent",
          color: "#000",
        },
      },
    },
  },
  card: {
    // marginBottom: "1rem",
    boxShadow: "unset !important",
    borderRadius: "14px",
  },
});

function CriarTarefa() {
  const params = useLocation();
  const taskEditData = params?.state?.task;
  const date = moment(taskEditData?.date).format("YYYY-MM-DD");

  const [refresh, setRefresh] = useState(0);
  const [meetingType, setMeetingType] = useState(taskEditData?.meetingType);
  const [meetingAt, setmeetingAt] = useState(taskEditData?.meetingAt);
  const [show, setShow] = useState(false);
  const [category, setCategory] = useState([]);
  const [categoryColor, setCategoryColor] = useState([]);
  const [alignment, setAlignment] = useState(taskEditData?.category?.id);
  const classes = useStyles();
  const [datePickerValue, setDatePickerValue] = React.useState(taskEditData ? date : "");
  // console.log("datePickerValue", datePickerValue);

  const [formValues, setFormValues] = useState({
    title: taskEditData ? taskEditData?.title : "",

    startTime: taskEditData ? taskEditData?.startTime : "",
    endTime: taskEditData ? taskEditData?.endTime : "",
    category: taskEditData ? taskEditData?.category.id : "",
    description: taskEditData ? taskEditData?.description : "",
    isAlert: taskEditData ? taskEditData?.isAlert : false,
  });

  // console.log("taskEditData", taskEditData);

  const navigate = useNavigate();

  useEffect(() => {
    getTaskCategory().then(res => {
      if (res.success) {
        setCategory(res.data.getAllTask);
      }
    });
  }, [refresh]);
  useEffect(() => {
    getTaskCategoryColor().then(res => {
      // console.log("res color", res);
      if (res.success) {
        setCategoryColor(res.data);
      }
    });
  }, []);

  const handleChangeMeeting = event => {
    setMeetingType(event.target.value);
  };

  const handleChangeMeetingAt = event => {
    setmeetingAt(event.target.value);
  };

  const onAddCategoryShow = () => {
    setShow(true);
  };

  // console.log("alignment", alignment);

  const handleButtonChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const handleFormChange = e => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };
  const handleSwitch = e => {
    setFormValues({ ...formValues, isAlert: e.target.checked });
  };

  const onSubmitTask = () => {
    const submitData = {
      ...(taskEditData && { id: taskEditData?.id }),
      ...formValues,
      meetingType,
      meetingAt,
      category: alignment,
      date: datePickerValue,
    };
    // console.log("submitData", submitData);
    if (!taskEditData) {
      addAdvocateTask(submitData).then(res => {
        if (res.success) {
          toast.success(res.message);
          navigate("/home/tarefas-semana");
        } else {
          toast.error(res.message);
        }
      });
    } else {
      editAdvocateTask(submitData).then(res => {
        if (res.success) {
          toast.success(res.message);
          navigate("/home/tarefas-semana");
        } else {
          toast.error(res.message);
        }
      });
    }
  };

  return (
    <div>
      <Box className={classes.root} sx={{ flexGrow: 1, background: "#F8F5FA" }} px={8} py={4}>
        <Box sx={{ display: "flex", alignItems: "center", color: "#5031A9", justifyContent: "space-between", marginBottom: "2rem" }}>
          <Typography onClick={() => navigate(-1)} sx={{ cursor: "pointer" }}>
            Voltar
          </Typography>
          {!taskEditData && (
            <Button variant="contained" sx={{ fontWeight: 600, fontFamily: "Poppins" }} onClick={onSubmitTask}>
              Salvar
            </Button>
          )}
        </Box>

        <Card
          variant="elevation"
          className={classes.card}
          sx={{ background: "#fff", marginBottom: "1rem", borderRadius: "13px", paddingX: 2 }}>
          <CardContent sx={{ position: "relative" }}>
            <Box>
              <Typography variant="h4">Informações</Typography>
            </Box>
            <Box sx={{ mt: 3 }}>
              <Box>
                <Grid container spacing={2}>
                  <Grid item md={12} sx={{ mt: 3 }}>
                    <StyledOutlinedInput defaultValue={formValues.title} placeholder="Título" name="title" onChange={handleFormChange} />
                  </Grid>
                  <Grid item md={6} sx={{ mt: 3 }}>
                    <FormControl variant="outlined" fullWidth>
                      <FormLabel>Hora de início</FormLabel>
                      <OutlinedInput
                        defaultValue={formValues.startTime}
                        id="startTime"
                        name="startTime"
                        type="time"
                        placeholder="Hora de início"
                        aria-describedby="outlined-weight-helper-text"
                        onChange={handleFormChange}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={6} sx={{ mt: 3 }}>
                    <FormControl variant="outlined" fullWidth>
                      <FormLabel>Fim do tempo</FormLabel>
                      <OutlinedInput
                        defaultValue={formValues.endTime}
                        id="endTime"
                        type="time"
                        name="endTime"
                        placeholder="Fim do tempo"
                        aria-describedby="outlined-weight-helper-text"
                        onChange={handleFormChange}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={12} sx={{ mt: 3 }}>
                    <DatePickerTranslated setDatePickerValue={setDatePickerValue} datePickerValue={datePickerValue} />
                    {/* <FormControl variant="outlined" fullWidth>
                      <FormLabel>Encontro</FormLabel>
                      <OutlinedInput
                        defaultValue={formValues.date}
                        value={formValues.date}
                        id="date"
                        name="date"
                        type="date"
                        placeholder="Encontro"
                        aria-describedby="outlined-weight-helper-text"
                        onChange={handleFormChange}
                      />
                    </FormControl> */}
                  </Grid>

                  {/* Skype */}
                  {/* <Grid item md={8} sx={{ mt: 3 }}>
                    <TextField
                      id="outlined-select-currency"
                      select
                      label="Presencial"
                      name="meetingType"
                      defaultValue={meetingType}
                      onChange={handleChangeMeeting}
                      fullWidth>
                      {meeting.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid> */}
                  {/* <Grid item md={4} sx={{ mt: 3 }}>
                    <TextField
                      id="outlined-select-currency"
                      select
                      label="Reunião em"
                      // name="meetingAt"
                      value={meetingAt}
                      onChange={handleChangeMeetingAt}
                      fullWidth>
                      {meetingTime.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <img src="/assets/images/Oval.png" style={{ paddingRight: 5, height: 20 }} />
                            {option.label}
                          </div>
                          <Typography></Typography>
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid> */}
                  {/* TextArea */}
                  <Grid item md={12} sx={{ mt: 3 }}>
                    <TextareaAutosize
                      aria-label="minimum height"
                      minRows={10}
                      name="description"
                      defaultValue={formValues.description}
                      // maxCol={8}
                      variant="outlined"
                      placeholder="Digite a nota aqui..."
                      style={{ fontSize: "15px", border: "2px solid #DBD7F4", borderRadius: "13px", width: "100%", resize: "none" }}
                      onChange={handleFormChange}
                    />
                  </Grid>
                  <Grid item md={12} sx={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
                    <Typography sx={{ fontWeight: 400, color: "#222B45" }}>Lembrar</Typography>
                    <Switch checked={formValues.isAlert} name="isAlert" onChange={handleSwitch} />
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </CardContent>
        </Card>

        <Box>
          <Typography variant="h5">Categoria</Typography>
          <Grid container>
            <Grid item md={4}>
              {category?.map((obj, indx) => (
                <ToggleButton
                  key={indx}
                  onChange={handleButtonChange}
                  value={obj.id}
                  style={{
                    border: `1px solid ${obj.color}`,
                    color: alignment === obj.id ? "#fff" : obj.color,
                    background: alignment === obj.id ? obj.color : "#fff",
                    margin: 5,
                  }}>
                  {obj.label}
                </ToggleButton>
              ))}
              {/* </ToggleButtonGroup> */}
              {/* <StyledButton
                btnText={"Ver mais"}
                variant="outlined"
                sx={{ backgroundColor: "", color: "#000", margin: 1, fontSize: "12px", padding: 1, fontWeight: 600 }}
              /> */}
              {!taskEditData && (
                <Button
                  variant="outlined"
                  onClick={onAddCategoryShow}
                  sx={{ backgroundColor: "", color: "#000", margin: 1, fontSize: "12px", padding: 1, fontWeight: 600 }}>
                  Adicionar
                </Button>
              )}
            </Grid>
          </Grid>
        </Box>
        {show && (
          <AddCategoryModal
            categoryColor={categoryColor}
            open={show}
            onHide={() => setShow(false)}
            setRefresh={setRefresh}
            refresh={refresh}
          />
        )}
      </Box>
    </div>
  );
}

export default CriarTarefa;
