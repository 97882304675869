import { AfterAuthApi, ApiCall } from "../index";
import {
  FORGOT_PASSWORD,
  VERIFY_FORGOT_PASSWORD,
  CHECK_PROVIDER_ID,
  GET_REGISTER_OFFICE,
  REGISTER_ADVOCATE,
  SOCIAL_AUTH,
  GET_PLAN,
  TRIAL_PLAN,
  SUBSCRIBE_PLAN,
} from "../url";

export const forgotPassword = submitData => {
  return new Promise((resolve, reject) => {
    ApiCall(FORGOT_PASSWORD, "post", submitData)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject();
      });
  });
};

export const verifyForgotPassword = submitData => {
  return new Promise((resolve, reject) => {
    ApiCall(VERIFY_FORGOT_PASSWORD, "post", submitData)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject();
      });
  });
};

export const verifyProviderId = submitData => {
  return new Promise((resolve, reject) => {
    ApiCall(CHECK_PROVIDER_ID, "post", submitData)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject();
      });
  });
};

export const getRegisterOffice = submitData => {
  return new Promise((resolve, reject) => {
    ApiCall(GET_REGISTER_OFFICE, "get", submitData)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject();
      });
  });
};

export const registerAdvocate = submitData => {
  return new Promise((resolve, reject) => {
    ApiCall(REGISTER_ADVOCATE, "post", submitData)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject();
      });
  });
};

export const socialAuth = submitData => {
  return new Promise((resolve, reject) => {
    ApiCall(SOCIAL_AUTH, "post", submitData)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject();
      });
  });
};

export const getPlans = () => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(GET_PLAN, "get")
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject();
      });
  });
};

export const getTrialPlans = () => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(TRIAL_PLAN, "post")
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject();
      });
  });
};

export const subscribePlan = submitData => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(SUBSCRIBE_PLAN, "post", submitData)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject();
      });
  });
};
