import React, { useEffect } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  InputAdornment,
  OutlinedInput,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { getChatAttachment } from "../../helper/API/chat";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    borderRadius: 160,
    marginBottom: "2rem",
    "& .MuiTab-textColorSecondary.Mui-selected": {
      color: "#ffff",
      zIndex: 60,
    },
    "& .MuiTabs-indicator": {
      height: "100% !important",
      borderRadius: 160,

      background: "linear-gradient(180deg, #FE2D66 0%, #A03E88 61.46%, #734699 91.15%) !important",
    },
    "& .MuiTab-wrapper": {
      textTransform: "capitalize",
      fontSize: 16,
      fontWeight: "bold",
    },
    "& .MuiPaper-elevation1": {
      boxShadow: "unset !important",
    },
    "& .MuiGrid-root": {
      "& .MuiPaper-root": {
        "& .MuiCardContent-root": {
          // padding: "0 0 5rem 0 !important",
        },
      },
      "& .MuiContainer-root": {
        padding: 5,
      },
    },
  },
  yourBtn: {
    // position: "relative",
    // top: "150px",
    // width: "150px",
    padding: "5px",
    // border: "1px dashed #BBB",
    textAlign: "center",
    // backgroundColor: "#DDD",
    cursor: "pointer",
    marginLeft: "10px",
  },
  imgPreview: {
    borderRadius: "50%",
    height: "70px",
    width: "70px",
  },
});

const AttachmentCard = () => {
  const classes = useStyles();

  return (
    <div>
      {" "}
      <Card variant="elevation" sx={{ borderRadius: "15px" }} className={classes.root}>
        <CardContent sx={{ padding: 0 }}>
          <Grid container>
            <Grid item xs={2} md={1} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <img src="/assets/images/file.png" />
            </Grid>
            <Grid item xs={7} md={10} sx={{ position: "relative", left: "-2%" }}>
              <Typography sx={{ fontWeight: 400, fontFamily: "Poppins" }}>{"attachment.fileName"}</Typography>
              <Typography sx={{ fontWeight: 400, fontFamily: "Poppins", color: "#6F737F" }}>{"attachment.ext"}</Typography>
            </Grid>
            <Grid item xs={3} md={1} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <a href="" target="_blank">
                <div
                  style={{
                    backgroundColor: "#8E91F4",
                    display: "flex",
                    justifyContent: "center",
                    width: "3rem",
                    padding: "0.5rem 0rem",
                    borderRadius: "50%",
                  }}>
                  {/* <img src="/assets/images/download.png" height={35} /> */}
                  <FileDownloadIcon sx={{ color: "#fff" }} />
                </div>
              </a>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};

export default AttachmentCard;
