export const TRANSLATIONS_EN = {
  title: "Welcome to react using react-i18next",
  description: {
    part1: "To get started,  and save to reload.",
    part2: "Switch language between english and german using buttons above.",
  },
  login: "Login",
  forgotPassword: "Forgot your password?",
  signInGoogle: "Sign in to Google",
  viewMore: "View More",
  financialGoal: "Financial Goal",
  targetClients: "Target Clients",
  clients: "Clients",
  notes: "Notes",
};
