import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Autocomplete from "@mui/material/Autocomplete";
import CloseIcon from "@mui/icons-material/Close";
import { addSubscriber, getSingleProcess } from "../../helper/API/process";
import { toast } from "react-toastify";
import { Typography } from "@mui/material";

const DefendantModal = ({ open, handleClose, TaskDetail, data, singleProcessData, setSingleProcessData }) => {
  const [value, setValue] = React.useState(null);

  const arrangeData = data?.findClient?.map((label, indx) => {
    return { label: label.name, id: label.id };
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);

    const submitData = {
      subscriberId: newValue.id,
      processId: TaskDetail.id,
      userType: "defendant",
    };

    addSubscriber(submitData).then(res => {
      if (res.success) {
        toast.success(res.message);
        const id = { id: TaskDetail.id };
        getSingleProcess(id).then(res => {
          if (res.success) {
            setSingleProcessData(res.data);
          }
          handleClose();
        });
      } else {
        toast.error(res.message);
        handleClose();
      }
    });
    // }
  };
  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
          <h3>Réu</h3>
          <h3>
            <CloseIcon onClick={handleClose} sc={{ cursor: "pointer" }} />
          </h3>
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mb: 15, mt: 3, height: "30vh" }}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={arrangeData}
              renderOption={(props, option) => (
                <li key={option} {...props}>
                  <Typography className="font-large yourDesiredClass">{option.label}</Typography>
                </li>
              )}
              onChange={handleChange}
              sx={{ position: "relative", zIndex: 500 }}
              noOptionsText={"Nenhuma opção disponível"}
              renderInput={params => <TextField {...params} label="Réu" sx={{ mt: 1 }} />}
            />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default DefendantModal;
