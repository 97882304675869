import React from "react";
import Chart from "react-apexcharts";

const FinanceiroDespesasChart = ({ chartData }) => {
  // console.log("chartData", chartData);
  const converBrazilNumber = x => {
    return Number(x)?.toLocaleString("pt-BR", {
      style: "currency",
      currency: "brl",
    });
  };
  const chartVal = chartData?.map((obj, index) => obj.total);
  const months = chartData?.map((obj, index) => obj.month);
  // console.log("chartVal", chartVal);
  // console.log("months", months);
  var data = {
    options: {
      chart: {
        height: "100vh",
        width: "100vw",
        type: "area",
        toolbar: {
          show: !1,
        },
        zoom: {
          enabled: !1,
        },
      },
      markers: {
        size: 0,
        colors: ["#B961FE"],
        strokeColors: "#fff",
        strokeWidth: 5,
        hover: {
          size: 10,
        },
      },
      plotOptions: {
        line: {
          horizontal: !1,
          columnWidth: "35%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      grid: {
        borderColor: "#E1E1E1",
        borderWidth: 10,
        strokeDashArray: 7,
        // clipMarkers: false,
        // yaxis: {
        //   lines: {
        //     show: false
        //   }
        // },
      },
      stroke: {
        show: !0,
        width: 3,
        curve: "smooth",
      },
      xaxis: {
        type: "month",
        // categories: ["JAN", "FEV", "MAR", "ABR", "MAI", "JUN", "JUL", "AGO", "SET", "OUT", "NOV", "DEZ"],
        categories: months,
        tooltip: {
          enabled: false,
        },
        crosshairs: {
          show: true,
          position: "back",
          stroke: {
            color: "rgba(58, 70, 102, 0.1)",
            width: 45,
            dashArray: 0,
          },
          fill: {
            type: "gradient",
            color: "blue", //sample color only
            gradient: {
              colorFrom: "blue", //sample color only
              colorTo: "blue", //sample color only
              stops: [0, 100, 0],
              opacityFrom: 0.2,
              opacityTo: 0.8,
            },
          },
        },
      },
      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          return (
            '<div style="background:linear-gradient(252.4deg, #F7BE2D -63.99%, #F78E2D 87.82%);padding:15px 35px;box-shadow: 0px 5.57377px 55.7377px rgba(0, 0, 0, 0.05);border-radius:30px;color:#fff;">' +
            "<span>" +
            converBrazilNumber(series[seriesIndex][dataPointIndex]) +
            // (series[seriesIndex][dataPointIndex] / 1000).toFixed(0) +
            "</span>" +
            "</div>"
          );
        },
      },
      yaxis: {
        labels: {
          formatter: function (value) {
            var val = Math.abs(value);
            if (val >= 1000) {
              val = "R$" + (val / 1000).toFixed(0) + "K";
            } else if (val <= 1000) {
              val = "R$" + val.toFixed(0);
            }
            return val;
          },
        },
      },
      colors: ["#B961FE"],
    },

    series: [
      {
        name: "series1",
        // data: [900, 100],
        data: chartVal,
      },
    ],
  };

  return (
    <div>
      <div className="app">
        <div className="row">
          <Chart options={data.options} series={data.series} type="line" width="100%" height="300px" />
        </div>
      </div>
    </div>
  );
};

export default FinanceiroDespesasChart;
