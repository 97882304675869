import React from "react";
import Box from "@mui/material/Box";
import { Button, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { experimentalStyled as styled } from "@mui/material/styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import DropdownFilterModal from "./DropDownFilterModal";
import { Link } from "react-router-dom";

const Img = styled("img")({
  display: "block",
  //   maxWidth: "40%",
  //   maxHeight: "100%",
});

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    borderRadius: 160,
    // maxWidth: 500,
    "& .MuiButtonBase-root": {
      background: "red",
    },
  },

  card: {
    // marginBottom: "1rem",
    boxShadow: "unset !important",
    borderRadius: "14px",
  },
});
const StyledButton = styled(Button)`
  &:hover {
    background-color: #fff;
  }
  &:focus {
    background-color: #fff;
  }
`;
const AdvanceSearchTemplate = ({ setLoading, setTableRows }) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [open, setOpen] = React.useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleOpen = () => {
    setOpen(true);
    // console.log("clicked");
  };
  return (
    <>
      <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
        <Box sx={{ mb: 7 }}>
          <StyledButton
            variant="contained"
            // disableElevation
            onClick={handleOpen}
            endIcon={<KeyboardArrowDownIcon />}
            sx={{
              borderRadius: "18px",
              diplay: "flex",
              justifyContent: "space-around",
              backgroundColor: "#fff",
              color: "#000",
              width: "100%",
              height: "100%",
              py: 2,
            }}
            className={classes.button}>
            <Img
              alt="profile"
              src="/assets/images/filter.png"
              sx={{
                maxWidth: "60px !important",
                maxHeight: "60px !important",
                paddingX: "1rem",
              }}
            />
            <Typography sx={{ color: "#3E4954", fontWeight: 600 }}>Busca Avançada</Typography>
          </StyledButton>
          {/* <DropdownFilter btnText={"Filtro"} imgSrc={"/assets/images/filter.png"} /> */}
        </Box>
        {/* <Box p={1}>
          <Link to={`/home/financeiro/despesas`}>
            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
              <Typography sx={{ color: "#5031A9" }}>Despesas</Typography>
              <KeyboardArrowDownIcon />
            </Box>
          </Link>
        </Box> */}
      </Box>
      {open && <DropdownFilterModal open={open} handleClose={() => setOpen(false)} setLoading={setLoading} setTableRows={setTableRows} />}
    </>
  );
};

export default AdvanceSearchTemplate;
