import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useState } from "react";
import { TextField, Typography } from "@mui/material";
import { deleteAdvocateWithEmail, deleteAdvocateWithOtp } from "../../helper/API/advocate";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import * as loginAction from "../../redux/login/action";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";

const ConfirmDelete = ({ open, handleClose, setDeleteConfirm, email, logoutUser }) => {
  const [deleteData, setDeleteData] = useState(false);
  const [otpVisible, setOtpVisible] = useState(false);
  const [Email, setEmail] = React.useState(email);
  const [otp, setOtp] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const { logout } = useAuth();

  const navigate = useNavigate();
  const handleSaveEmail = () => {
    // Varify EMAIL api HERE
    setLoading(true);
    deleteAdvocateWithEmail().then(res => {
      if (res.success) {
        setLoading(false);
        toast.success(res.message);
        setOtpVisible(true);
        setEmail("");
      } else {
        setLoading(false);
        toast.error(res.message);
      }
    });
  };
  const handleSaveOTP = () => {
    // Varify OTP api HERE
    setLoading(true);
    const data = { otp };
    deleteAdvocateWithOtp(data).then(res => {
      if (res.success) {
        setLoading(false);
        toast.success(res.message);
        // setOtpVisible(true);
        logout();
        logoutUser();
        localStorage.clear();
        navigate("/");
        handleClose();
      } else {
        setLoading(false);
        toast.error(res.message);
      }
    });
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" fullWidth>
        <DialogTitle id="alert-dialog-title">
          {!deleteData ? "Confirme a exclusão do advogado" : !otpVisible ? "Digite o e-mail" : "Digite OTP"}
        </DialogTitle>
        <DialogContent>
          {!deleteData ? (
            <DialogContentText id="alert-dialog-description">Tem certeza de que deseja excluir? </DialogContentText>
          ) : !otpVisible ? (
            <>
              <Typography
                sx={{ border: "1px solid linen", backgroundColor: "dimgrey", opacity: 1, color: "linen", padding: "10px 1rem" }}
                // margin="normal"
                // placeholder="Digite seu E-mail "
                // required
                // fullWidth
                // id="email"
                // label="Endereço de E-mail"
                // name="email"
                // autoComplete="email"
                // autoFocus
                // value={Email}
                // disabled
              >
                {Email}
              </Typography>
            </>
          ) : (
            <TextField
              margin="normal"
              placeholder="Digite sua otp"
              required
              fullWidth
              name="otp"
              label="OTP"
              type="text"
              id="otp"
              defaultValue={otp}
              onChange={e => setOtp(e.target.value)}
            />
          )}
        </DialogContent>
        {!deleteData ? (
          <DialogActions>
            <Button onClick={handleClose}>Não</Button>
            <Button onClick={() => setDeleteData(true)} autoFocus>
              Sim
            </Button>
          </DialogActions>
        ) : !otpVisible ? (
          <DialogActions>
            <Button onClick={handleSaveEmail} autoFocus disabled={loading}>
              Enviar OTP
            </Button>
          </DialogActions>
        ) : (
          <DialogActions>
            <Button onClick={handleSaveOTP} autoFocus disabled={loading}>
              Enviar
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
};

const mapStateToProps = state => {
  // console.log("state", state);
  return {
    email: state?.loginUsers?.loginUser?.user?.email,
  };
};

const mapDispatchToProp = dispatch => {
  return {
    logoutUser: () => dispatch(loginAction.logout()),
  };
};

export default connect(mapStateToProps, mapDispatchToProp)(ConfirmDelete);
