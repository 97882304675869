import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { useTranslation } from "react-i18next";
import { experimentalStyled as styled } from "@mui/material/styles";
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  FormControl,
  Grid,
  MenuItem,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import { Container } from "@mui/material";
import StyledTextField from "../components/Inputs/StyledTextField";
// import CustomizedSearchBar from "../components/Inputs/SearchBar";
import { useNavigate } from "react-router-dom";
import OfficeDataSelect from "../components/office/OfficeDataSelect";
import { configOffice, editOffice, getOccupationType, getSingleOfficeDetail } from "../helper/API/office";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import * as officeAction from "../redux/office/action";
import * as advocateAction from "../redux/advogadoProfile/action";
import CancelIcon from "@mui/icons-material/Cancel";
import Loader from "../components/Loader";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AdvogadosList from "../components/office/AdvogadosList";

const Img = styled("img")({
  display: "block",
});

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    borderRadius: 10,
    marginBottom: "1rem",

    "& .MuiGrid-root": {
      "& .MuiFormControl-root": {
        "& .MuiFormLabel-root": {
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: 600,
          fontSize: "16px",
          lineHeight: "24px",
          color: "#333333",
        },
      },
    },

    // maxWidth: 500,
    "& .MuiTab-textColorSecondary.Mui-selected": {
      color: "#ffff",
      zIndex: 60,
    },
    "& .MuiTabs-indicator": {
      height: "100% !important",
      borderRadius: 160,

      background: "linear-gradient(180deg, #FE2D66 0%, #A03E88 61.46%, #734699 91.15%) !important",
    },
    "& .MuiTab-wrapper": {
      textTransform: "capitalize",
      fontSize: 16,
      fontWeight: "bold",
    },

    "& .MuiInputBase-root": {
      padding: "5px 5px !important",
      borderRadius: "13px",
    },
    "& .MuiPaper-elevation": {
      boxShadow: "unset !important",
    },
    "&::placeholder": {
      textOverflow: "ellipsis !important",
      color: "#000",
    },
    "& .MuiFormLabel-root": {
      color: "#000",
    },
  },
  input: {
    "&::placeholder": {
      textOverflow: "ellipsis !important",
      color: "#000",
    },
  },
  actionContainer: {
    background: "#D6D9FF",
    borderTopRightRadius: "13px",
    borderBottomRightRadius: "13px",
  },
  title: {
    fontFamily: "Poppins",
    color: "#FFFFFF",
  },
  subtitle: {
    fontFamily: "Poppins",
    color: "#FFFFFF",
  },

  card: {
    boxShadow: "unset !important",
    borderRadius: 11,
  },
  yourBtn: {
    // position: "relative",
    // top: "150px",
    // width: "150px",
    padding: "5px",
    border: "1px dashed #BBB",
    textAlign: "center",
    backgroundColor: "#DDD",
    cursor: "pointer",
    marginLeft: "10px",
  },
  imgPreview: {
    borderRadius: "50%",
    height: "70px",
    width: "70px",
  },
});

const Office = ({ getOfficeDetails, singleOfficeData, getAdvogadoProfile, permission }) => {
  const tabValue = JSON.parse(localStorage.getItem("value"));
  const [occupationType, setOccupationType] = useState(null);
  const [getOfficeData, setGetOfficeData] = useState(singleOfficeData);
  const [selectVal, setSelectVal] = useState(getOfficeData ? getOfficeData?.occupationArea?.label : "");
  const [occupationId, setOccupationId] = useState(getOfficeData ? getOfficeData?.occupationArea?.id : "");
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const classes = useStyles();
  const [value, setValue] = useState(tabValue ? tabValue : 0);
  const { t } = useTranslation();
  const [formValues, setFormValues] = useState(getOfficeData ? getOfficeData?.socialLink : [""]);
  const hiddenFileInput = React.useRef(null);
  const [images, setImages] = React.useState("");
  const [imagePreview, setImagePreview] = React.useState(getOfficeData ? getOfficeData?.avatar : null);
  const [fetchData, setFetchData] = React.useState(null);
  const [disable, setdisable] = React.useState(true);
  const [loading, setLoading] = React.useState(false);

  const [formField, setFormFields] = useState({
    name: getOfficeData ? getOfficeData.name : "",
    email: getOfficeData ? getOfficeData.email : "",
    telephone: getOfficeData ? getOfficeData.telephone : "",
    identityId: getOfficeData ? getOfficeData.identityId : "",
    socialReason: getOfficeData ? getOfficeData.socialReason : "",
    website: getOfficeData ? getOfficeData.website : "",
    postal: "",
    street: fetchData?.logradouro ? fetchData?.logradouro : getOfficeData ? getOfficeData.street : "",
    city: getOfficeData ? getOfficeData.city : "",
    district: getOfficeData ? getOfficeData.district : fetchData ? fetchData?.bairro : "",
    state: getOfficeData ? getOfficeData.state : fetchData ? fetchData.uf : "",
    room: getOfficeData ? getOfficeData.room : fetchData ? fetchData?.localidade : "",
    officeDesc: getOfficeData ? getOfficeData.officeDesc : "",
    number: getOfficeData ? getOfficeData.number : fetchData ? fetchData?.ibge : "",
    cep: getOfficeData ? getOfficeData.cep : "",
  });

  useEffect(() => {
    getOfficeDetails()
      .then(res => {
        // console.log("res effect", res);
        setGetOfficeData(res);

        setFormFields({
          name: res.name ? res.name : "",
          email: res.email ? res.email : "",
          telephone: res.telephone ? res.telephone : "",
          identityId: res.identityId ? res.identityId : "",
          socialReason: res.socialReason ? res.socialReason : "",
          website: res.website ? res.website : "",
          postal: res.postal ? res.postal : "",
          street: res.street ? res.street : "",
          city: res.city ? res.city : "",
          district: res.district ? res.district : "",
          state: res.state ? res.state : "",
          room: res.room ? res.room : "",
          officeDesc: res.officeDesc ? res.officeDesc : "",
          cep: res.officeDesc ? res.officeDesc : "",
          number: "",
        });
        setFormValues(res ? res?.socialLink : [""]);
      })
      .catch(err => {
        setFormFields({
          name: "",
          email: "",
          telephone: "",
          identityId: "",
          socialReason: "",
          website: "",
          postal: "",
          street: "",
          city: "",
          district: "",
          state: "",
          room: "",
          officeDesc: "",
          cep: "",
          number: "",
        });
      });
  }, []);
  useEffect(() => {
    setFormFields({
      name: getOfficeData && getOfficeData.name,
      email: getOfficeData && getOfficeData.email,
      telephone: getOfficeData && getOfficeData.telephone,
      identityId: getOfficeData && getOfficeData.identityId,
      socialReason: getOfficeData && getOfficeData.socialReason,
      website: getOfficeData && getOfficeData.website,
      postal: "",
      street: fetchData?.logradouro ? fetchData?.logradouro : getOfficeData ? getOfficeData.street : "",
      city: getOfficeData && getOfficeData.city,
      district: getOfficeData ? getOfficeData.district : fetchData ? fetchData?.bairro : "",
      state: getOfficeData ? getOfficeData.state : fetchData ? fetchData.uf : "",
      room: getOfficeData ? getOfficeData.room : fetchData ? fetchData?.localidade : "",
      officeDesc: getOfficeData && getOfficeData.officeDesc,
      number: getOfficeData ? getOfficeData.number : fetchData ? fetchData?.ibge : "",
      cep: getOfficeData && getOfficeData.cep,
    });
    setOccupationId(getOfficeData && getOfficeData?.occupationArea?.id);
    setSelectVal(getOfficeData && getOfficeData?.occupationArea?.label);
    setFormValues(getOfficeData && getOfficeData?.socialLink);
  }, [getOfficeData]);

  // console.log("getOfficeData", getOfficeData);

  // console.log("formField", formField);

  useEffect(() => {
    //  const ab =  await fetch("https://viacep.com.br/ws/24753550/json/");

    //  console.log('ab', ab)
    getOccupationType().then(res => {
      if (res.data.success) {
        setOccupationType(res?.data?.data);
        if (getOfficeData) {
          const selectOccupation = res?.data?.data?.filter(obj => obj?.id === getOfficeData?.occupationArea)[0];
          setSelectVal(selectOccupation?.label);
        }
      }
    });
  }, []);

  useEffect(() => {
    setGetOfficeData(singleOfficeData);
  }, [singleOfficeData]);

  // console.log("getOfficeData", getOfficeData);
  // console.log("formFields", formField);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
    hiddenFileInput.current.click();
  };

  const handleImageChange = event => {
    const fileUploaded = event.target.files[0];
    const maxSize = 1024 * 1024; // 1MB in bytes

    if (fileUploaded.size < maxSize) {
      if (event.target.files[0]) {
        setImages(event.target.files[0]);
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          setImagePreview(reader.result);
        });
        reader.readAsDataURL(event.target.files[0]);
      }
    } else {
      toast.error("Selecione uma imagem com menos de 1 MB");
    }
  };

  const handleChange = (event, newValue) => {
    localStorage.setItem("value", JSON.stringify(newValue));
    setValue(newValue);
  };

  let handleTypeChange = (i, e) => {
    let newFormValues = [...formValues];
    newFormValues[i] = e.target.value;
    setFormValues(newFormValues);
  };

  let addFormFields = () => {
    setFormValues([...formValues, ""]);
  };

  let removeFormFields = i => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };
  // console.log("formValues", formValues);

  const navigate = useNavigate();

  const onAdvogadosCard = id => {
    if (permission?.isOwner && permission?.teamMgt) {
      getAdvogadoProfile(id).then(res => {
        // console.log("res", res);
        if (res.success) {
          navigate("/home/advogados-profile");
        } else {
          toast.error(res.message);
        }
      });
    }
    // console.log("e.target.name", permission);
  };

  const handleChangeForm = e => {
    // console.log("e.target.name", e.target.value.replace("-", ""));
    e.preventDefault();
    if (e.target.name === "cep" && (e.target.value.length === 8 || e.target.value.length === 9)) {
      fetch(`https://viacep.com.br/ws/${e.target.value.replace("-", "")}/json/`)
        .then(response => response.json())
        .then(data => {
          setFetchData(data);
          setdisable(false);
          // console.log("setFetchData(data)", data);
          if (!data.erro) {
            setFormFields({
              ...formField,
              cep: e.target.value,
              street: data?.logradouro,
              district: data?.bairro,
              state: data.uf,
              room: data?.localidade,
              number: data?.ibge,
            });
          }
        });
    }
    setFormFields({
      ...formField,
      [e.target.name]: e.target.value,
    });
  };
  const handleSelectChange = e => {
    setOccupationId(e.target.value);
  };

  const handleConfigOffice = () => {
    const newFormvalues = formValues.filter(val => {
      if (Object.keys(val).length !== 0) {
        return val;
      }
    });
    const submitData = { ...formField, occupationArea: occupationId, socialLink: newFormvalues, images, id: getOfficeData?.id };
    // console.log("submitData", submitData);
    setLoading(true);
    if (!getOfficeData) {
      configOffice(submitData).then(res => {
        if (res.success) {
          toast.success(res.message);
          getSingleOfficeDetail().then(res => {
            // console.log("res get office details", res);
            setGetOfficeData(res);
            setLoading(false);
          });
          // navigate("/home");
        } else {
          toast.error(res.message);
          setLoading(false);
        }
      });
    } else {
      // console.log("submitData edit", submitData);
      setLoading(true);
      editOffice(submitData).then(res => {
        // console.log("res", res);
        if (res.success) {
          toast.success(res.message);
          getOfficeDetails()
            .then(res => {
              // console.log("res effect:>:::", res);
              setGetOfficeData(res);
              setLoading(false);
            })
            .catch(err => setGetOfficeData());
        } else {
          toast.error(res.message);
          setLoading(false);
        }
      });
    }
  };
  // console.log("image", images);

  return (
    <div>
      <Box className={classes.root} sx={{ flexGrow: 1, background: "#F8F5FA" }} px={8} py={4}>
        <Box sx={{ width: "100%" }}>
          <Tabs
            TabIndicatorProps={{ style: { display: "none" } }}
            onChange={handleChange}
            value={value}
            aria-label="Tabs where selection follows focus"
            sx={{ border: "1px solid #E1E1FB" }}
            // selectionFollowsFocus
          >
            <Tab label="Escritório" sx={{ fontFamily: "Poppins", fontWeight: 400 }} />
            <Tab label="Advogados" sx={{ fontFamily: "Poppins", fontWeight: 400 }} />
          </Tabs>
        </Box>

        {
          value === 0 && (
            // (!getOfficeData  ? (
            //   <Loader />
            // ) : (
            <Grid container spacing={{ xs: 2, md: 3 }} className={classes.root}>
              <Grid item xs={12} sm={12} md={12}>
                <Card variant="elevation" sx={{ cursor: "pointer" }}>
                  <CardContent>
                    <Card variant="elevation" className={classes.card}>
                      <Box>
                        <Typography variant="h5">Dados do escritório</Typography>
                      </Box>

                      {/* <CardContent> */}
                      <Grid container spacing={4} mt={1}>
                        {/* <Grid item md={6}>
                        <StyledTextField
                          name="name"
                          label="Nome"
                          placeholder="Digite o nome"
                          onChange={handleChangeForm}
                          defaultValue={formField.name}
                        />
                      </Grid> */}
                        <Grid item md={6}>
                          <StyledTextField name="name" label="Nome" placeholder="Nome" onChange={handleChangeForm} value={formField.name} />
                        </Grid>
                        <Grid item md={6}>
                          <StyledTextField
                            name="socialReason"
                            label="Razão Social"
                            placeholder="Digite a razão Social"
                            onChange={handleChangeForm}
                            value={formField.socialReason}
                          />
                        </Grid>

                        <Grid item md={6}>
                          <StyledTextField
                            name="identityId"
                            label="CNPJ/CPF"
                            placeholder="Digite o CPF OU CNPJ"
                            onChange={handleChangeForm}
                            value={formField.identityId}
                          />
                        </Grid>
                        <Grid item md={6}>
                          <StyledTextField
                            name="number"
                            label="Number"
                            placeholder="number"
                            onChange={handleChangeForm}
                            defaultValue={formField.number}
                            value={formField.number}
                            disable={disable}
                          />
                        </Grid>
                        <Grid item md={6}>
                          <StyledTextField
                            name="telephone"
                            label="Telefone"
                            placeholder="Digite o telefone"
                            onChange={handleChangeForm}
                            defaultValue={formField.telephone}
                            value={formField.telephone}
                          />
                        </Grid>
                        <Grid item md={6}>
                          <StyledTextField
                            name="email"
                            label="E-mail"
                            placeholder="Digite o E-mail"
                            onChange={handleChangeForm}
                            defaultValue={formField.email}
                            value={formField.email}
                          />
                        </Grid>

                        {/* DROPDOWN */}
                        <Grid item md={6}>
                          {/* <Container
                            id="basic-button"
                            aria-controls={open ? "basic-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? "true" : undefined}
                            onClick={handleClick}
                            sx={{
                              paddingLeft: "unset !important",
                              cursor: "pointer",
                              width: "100%",
                              marginLeft: "unset !important",
                              position: "relative",
                            }}>
                            <StyledTextField
                              value={selectVal}
                              defaultValue={selectVal}
                              label="Área de atuação"
                              placeholder="Selecione uma área"
                              fullWidth
                            />
                          </Container>
                          <OfficeDataSelect
                            selected={selectVal}
                            open={open}
                            handleClick={handleClick}
                            handleClose={() => setAnchorEl(null)}
                            setAnchorEl={setAnchorEl}
                            anchorEl={anchorEl}
                            setSelectVal={setSelectVal}
                            data={occupationType}
                            setOccupationId={setOccupationId}
                          /> */}
                          <FormControl fullWidth>
                            <TextField
                              // id="standard-select-currency"
                              placeholder="elecione uma área"
                              select
                              name="userType"
                              variant="outlined"
                              defaultValue={occupationId}
                              value={occupationId}
                              onChange={handleSelectChange}>
                              {getOfficeData && <MenuItem value={occupationId}>{selectVal}</MenuItem>}
                              {occupationType?.map((obj, indx) => (
                                <MenuItem value={obj.id} key={indx}>
                                  {obj.label}
                                </MenuItem>
                              ))}
                            </TextField>
                          </FormControl>
                        </Grid>
                        <Grid item md={6}>
                          <StyledTextField
                            name="website"
                            label="Website"
                            placeholder="Insira o link do website"
                            onChange={handleChangeForm}
                            defaultValue={formField.website}
                            value={formField.website}
                          />
                        </Grid>
                        <Grid item md={6}>
                          <StyledTextField
                            name="cep"
                            label="CEP"
                            placeholder="CEP"
                            // onInput={e => {
                            //   e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 8);
                            // }}
                            inputProps={{
                              maxLength: 8,
                            }}
                            onChange={handleChangeForm}
                            defaultValue={formField.cep}
                            value={formField.cep}
                          />
                        </Grid>
                        {/* <Grid item md={6}>
                          <StyledTextField
                            name="postal"
                            label="Endereço"
                            placeholder="Endereço"
                            onChange={handleChangeForm}
                            defaultValue={formField.postal}
                          />
                        </Grid> */}
                        <Grid item md={6}>
                          <StyledTextField
                            name="city"
                            label="Cidade"
                            placeholder="Cidade"
                            onChange={handleChangeForm}
                            defaultValue={formField.city}
                            value={formField.city}
                            disable={disable}
                          />
                        </Grid>
                        <Grid item md={6}>
                          <StyledTextField
                            name="state"
                            label="Estado"
                            placeholder="Digite aqui"
                            onChange={handleChangeForm}
                            defaultValue={formField.state}
                            value={formField.state}
                          />
                        </Grid>
                        <Grid item md={6}>
                          <TextField
                            name="street"
                            onChange={handleChangeForm}
                            label="Rua"
                            fullWidth
                            // disable={disable}
                            placeholder="Rua"
                            // key={formField.street}
                            value={formField.street}
                            defaultValue={formField.street}
                          />
                        </Grid>
                        <Grid item md={6}>
                          <StyledTextField
                            name="district"
                            label="Bairro"
                            disable={disable}
                            placeholder="Bairro"
                            onChange={handleChangeForm}
                            defaultValue={formField.district}
                            value={formField.district}
                          />
                        </Grid>

                        <Grid item md={6}>
                          <StyledTextField
                            name="room"
                            label="Sala"
                            placeholder="Sala"
                            onChange={handleChangeForm}
                            defaultValue={formField.room}
                            value={formField.room}
                            disable={disable}
                          />
                        </Grid>

                        {formValues?.map((element, index) => (
                          <Grid item md={6} sx={{ display: "inline-block", position: "relative" }} key={index}>
                            <StyledTextField
                              label="Redes sociais"
                              defaultValue={formValues[index]}
                              value={formValues[index]}
                              placeholder="Insira aqui"
                              onChange={e => handleTypeChange(index, e)}
                              sx={{ width: "100%" }}
                            />
                            {formValues[index] ? (
                              // <Button variant="contained">
                              <CancelIcon
                                sx={{ fontWeight: 600, fontFamily: "Poppins", mt: 1, position: "absolute", right: "1%", top: "45%" }}
                                onClick={() => removeFormFields(index)}
                              />
                            ) : // </Button>
                            null}
                          </Grid>
                        ))}
                        <Grid item md={3}>
                          <Button variant="contained" sx={{ fontWeight: 600, fontFamily: "Poppins" }} onClick={() => addFormFields()}>
                            <AddCircleIcon sx={{ pr: 1 }} /> Adicionar
                          </Button>
                        </Grid>
                        <Grid item md={3} display="flex" justifyContent="right">
                          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <div style={{ display: "flex", alignItems: "center" }}>
                              <Img src={imagePreview ? imagePreview : "/assets/images/uploadImg.png"} className={classes.imgPreview} />
                              <div className={classes.yourBtn} onClick={handleClick}>
                                Adicione seu logo
                              </div>

                              <div style={{ height: "0px", width: "0px", overflow: "hidden" }}>
                                <input
                                  id="upfile"
                                  type="file"
                                  accept={"image/*"}
                                  ref={hiddenFileInput}
                                  onChange={handleImageChange}
                                  style={{ display: "none" }}
                                />
                              </div>
                            </div>
                          </div>
                        </Grid>
                        <Grid item md={12}>
                          <TextareaAutosize
                            aria-label="minimum height"
                            minRows={10}
                            // maxCol={8}
                            name="officeDesc"
                            placeholder="Descreva uma breve descrição sobre seu escritorio e áreas de atuação"
                            style={{ border: " solid #DBD7F4", borderRadius: "13px", width: "99%", resize: "none" }}
                            defaultValue={formField.officeDesc}
                            onChange={handleChangeForm}
                          />
                        </Grid>
                        <Grid item md={12} sx={{ textAlign: "center" }}>
                          <Button variant="contained" onClick={handleConfigOffice}>
                            Salvar
                            {loading && (
                              <CircularProgress
                                size={24}
                                sx={{
                                  marginLeft: "12px",
                                }}
                              />
                            )}
                          </Button>
                        </Grid>
                      </Grid>

                      {/* </CardContent> */}
                    </Card>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          )
          // ))
        }
        {value === 1 && (
          <>
            <AdvogadosList onAdvogadosCard={onAdvogadosCard} classes={classes} />
          </>
        )}
      </Box>
    </div>
  );
};

const mapStateToProp = state => {
  // console.log("state", state);
  return {
    singleOfficeData: state?.officeReducer?.officeData?.data?.data,
    permission: state?.getProfileReducer?.profile,
  };
};
const mapDispatchToProp = dispatch => {
  return {
    getOfficeDetails: () => dispatch(officeAction.getOfficeData()),
    setOffice: data => dispatch(officeAction.setOfficeData(data)),
    getAdvogadoProfile: id => dispatch(advocateAction.getProfileData(id)),
  };
};

export default connect(mapStateToProp, mapDispatchToProp)(Office);
