import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Grid, Typography } from "@mui/material";

const SubscribedProccessModal = ({ open, handleClose, processModalData, closeModal }) => {
  const [data, setData] = React.useState(processModalData);
  React.useEffect(() => {
    setData(processModalData);
  }, [processModalData]);
  // console.log("data", data);
  return (
    <div>
      <Dialog
        open={open}
        // onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="sm">
        <DialogTitle id="alert-dialog-title">Lista de processos</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {data.map((obj, i) => {
              return (
                <Grid container>
                  <Grid item md={12} sx={{ display: "flex", alignItems: "center" }}>
                    <Typography>{obj?.numProcesso} : </Typography>
                    <Typography sx={{ ml: 2 }}>{obj?.status}</Typography>
                  </Grid>
                </Grid>
              );
            })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal}>Fechar</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default SubscribedProccessModal;
