import React, { useEffect, useState } from "react";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormLabel,
  Grid,
  InputAdornment,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import NativeSelect from "@mui/material/NativeSelect";
import InputBase from "@mui/material/InputBase";
import { filterProcess, getProcessStatus } from "../../helper/API/process";
import { toast } from "react-toastify";
import DatePickerTranslated from "../DatePickerTranslated";

const Img = styled("img")({
  display: "block",
  //   maxWidth: "40%",
  //   maxHeight: "100%",
});

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    // width: "100%",
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      border: "none",
      // borderColor: '#80bdff',
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));

const DropdownFilter = ({ setFormValues, formValues, open, handleClose, setProcessList, setLoading }) => {
  const [age, setAge] = useState("");
  const [statusProcess, setStatusProcess] = useState([]);
  const [datePickerValue, setDatePickerValue] = React.useState("");
  const [toDatePickerValue, setToDatePickerValue] = React.useState("");

  const handleChange = e => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    getProcessStatus().then(res => {
      // console.log("statusProcess res", res);
      if (res.success) {
        setStatusProcess(res.data);
      } else {
        toast.error(res.message);
      }
    });
  }, []);

  const onFilterProcess = () => {
    setLoading(true);
    const submitData = {
      filter: {
        ...formValues,
        fromDate: datePickerValue,
        toDate: toDatePickerValue,
        minAmount: formValues.minAmount,
        maxAmount: formValues.maxAmount,
      },
    };
    filterProcess(submitData).then(res => {
      // console.log("res filter api", res);
      if (res.success) {
        setLoading(false);
        setProcessList(res.data);
        handleClose();
      } else {
        setLoading(false);
        toast.error(res.message);
      }
    });
  };
  // console.log("datePickerValue", datePickerValue);
  // console.log("toDatePickerValue", toDatePickerValue);

  return (
    <div>
      <div>
        {/* <Button onClick={handleOpen}>Open modal</Button> */}

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth>
          <DialogTitle id="alert-dialog-title" sx={{ display: "flex", alignItems: "center" }}>
            <Img
              alt="profile"
              src="/assets/images/filter.png"
              sx={{
                maxWidth: "60px !important",
                maxHeight: "60px !important",
                paddingX: "1rem",
              }}
            />
            <Typography sx={{ color: "#3E4954", fontWeight: 600, fontSize: "25px" }}>Filtro</Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Box>
                <Grid container>
                  <Grid item md={12}>
                    <TextField
                      id="outlined-basic"
                      label="Cliente"
                      name="client"
                      variant="outlined"
                      fullWidth
                      placeholder="Digite o nome do cliente"
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item md={6} sx={{ mt: 3, pr: 2 }}>
                    <TextField
                      label="Valor mínimo"
                      id="outlined-start-adornment"
                      name="minAmount"
                      onChange={handleChange}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                      }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={6} sx={{ mt: 3 }}>
                    <TextField
                      label="Valor máximo"
                      id="outlined-start-adornment"
                      name="maxAmount"
                      onChange={handleChange}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                      }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={6} sx={{ mt: 3, pr: 2 }}>
                    <FormControl variant="outlined" fullWidth>
                      <FormLabel>data inicial</FormLabel>
                      {/* <OutlinedInput
                        // value={formValues.date}
                        id="date"
                        name="fromDate"
                        type="date"
                        placeholder="Encontro"
                        aria-describedby="outlined-weight-helper-text"
                        onChange={handleChange}
                      /> */}
                      <DatePickerTranslated setDatePickerValue={setDatePickerValue} datePickerValue={datePickerValue} />
                    </FormControl>
                  </Grid>
                  <Grid item md={6} sx={{ mt: 3 }}>
                    <FormControl variant="outlined" fullWidth>
                      <FormLabel>data final</FormLabel>
                      {/* <OutlinedInput
                        // value={formValues.date}
                        id="date"
                        name="toDate"
                        type="date"
                        placeholder="Encontro"
                        aria-describedby="outlined-weight-helper-text"
                        onChange={handleChange}
                      /> */}
                      <DatePickerTranslated setDatePickerValue={setToDatePickerValue} datePickerValue={toDatePickerValue} />
                    </FormControl>
                  </Grid>
                  <Grid item md={12} sx={{ mt: 3 }}>
                    <TextField
                      id="outlined-basic"
                      name="attorney"
                      label="Advogado"
                      variant="outlined"
                      fullWidth
                      placeholder="Digite o nome do advogado"
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item md={6} sx={{ mt: 3 }}>
                    <FormControl sx={{ m: 1 }} variant="standard" fullWidth>
                      <NativeSelect
                        id="demo-customized-select-native"
                        name="status"
                        value={formValues.status}
                        onChange={handleChange}
                        input={<BootstrapInput />}>
                        {/* <option aria-label="None" value="" disabled>6</option> */}
                        {statusProcess?.map((obj, indx) => (
                          <option value={obj.id}>
                            <span>{obj.label}</span>
                          </option>
                        ))}
                        {/* <option value={20}>
                          <Img
                            alt="profile"
                            src="/assets/images/check-circle.png"
                            sx={{
                              maxWidth: "60px !important",
                              maxHeight: "60px !important",
                              paddingX: "1rem",
                            }}
                          />{" "}
                          Em andamento
                        </option>
                        <option value={30}>
                          <Img
                            alt="profile"
                            src="/assets/images/check-circle.png"
                            sx={{
                              maxWidth: "60px !important",
                              maxHeight: "60px !important",
                              paddingX: "1rem",
                            }}
                          />{" "}
                          Concluído
                        </option> */}
                      </NativeSelect>
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ padding: 4 }}>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button
              onClick={onFilterProcess}
              autoFocus
              sx={{ "&:hover": { backgroundColor: "#73469980" }, background: "#734699", color: "#fff" }}>
              Aplicar
            </Button>
          </DialogActions>
        </Dialog>

        {/* <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    maxWidth="md"
                >
                    <Box sx={style}>
                        <Grid container>
                            <Grid item md={12}>
                                <TextField id="outlined-basic" label="Cliente" variant="outlined" fullWidth placeholder='Digite o nome do cliente' />
                            </Grid>
                            <Grid item md={6} sx={{ mt: 3 }}>
                                <TextField
                                    label="With normal TextField"
                                    id="outlined-start-adornment"
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">kg</InputAdornment>,
                                    }}
                                />
                            </Grid>
                            <Grid item md={12}>
                                item
                            </Grid>
                        </Grid>
                    </Box>
                </Modal> */}
      </div>
    </div>
  );
};
export default DropdownFilter;
