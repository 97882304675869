import { AfterAuthApi } from "../../helper";
import { GET_OFFICE_SUCCESSFUL, OFFICE_PROCESSING, STOP_OFFICE_PROCESSING } from "./type";
import { GET_SINGLE_OFFICE_DETAILS } from "../../helper/url";

export const setOfficeData = payload => {
  return {
    type: GET_OFFICE_SUCCESSFUL,
    payload,
  };
};

export const getOfficeData = payload => dispatch => {
  return new Promise((resolve, reject) => {
    // console.log("payload", payload);
    dispatch({ type: OFFICE_PROCESSING });
    AfterAuthApi(GET_SINGLE_OFFICE_DETAILS, "post")
      .then(office => {
        dispatch({
          type: GET_OFFICE_SUCCESSFUL,
          payload: office,
        });
        resolve(office.data.data);
      })
      .catch(error => {
        dispatch({ type: STOP_OFFICE_PROCESSING });
        // console.log("error in office action", error.message);
      });
  });
};
