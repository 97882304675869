import React, { useEffect, useState, useRef, useContext } from "react";
import { Button, Card, CardContent, FormControl, Grid, InputAdornment, OutlinedInput, TextField, Typography } from "@mui/material";
import { Container } from "@mui/system";
import EquipCard from "../Processos/EquipCard";
import SendIcon from "@mui/icons-material/Send";
import TimeStampCard from "./TimeStampCard";
import { io } from "socket.io-client";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import ScrollToBottom from "react-scroll-to-bottom";
import { getAllChat } from "../../helper/API/chat";
// import { SOCKET_URL } from "../../config";
// import { SocketContext } from "../../context/socket";
import Loader from "../Loader";
import RecordFound from "../RecordFound/RecordFound";
// var socket = io(SOCKET_URL);

const EquipeTab = ({ classes, loginUser, socket }) => {
  const params = useLocation();
  // const socket = useContext(SocketContext);

  const processData = JSON.parse(localStorage.getItem("process"));
  const loginUserId = loginUser.loginUsers.loginUser?.user?._id;
  // console.log("loginUserId", loginUserId);

  const advocateId = processData.advocateOwner;
  const ProcessId = processData.id;
  const fromName = loginUser.loginUsers.loginUser?.user?.name;
  const avatar = loginUser.loginUsers.loginUser?.user.avatar;

  const [message, setMessage] = useState({
    sender: loginUserId,
    processId: ProcessId,
    advocateId: advocateId,
    from: fromName,
    msgTime: "",
    message: "",
    response: [],
    avatar: avatar,
  });
  const [resMessage, setResMessage] = useState({
    id: "",
    sender: loginUserId,
    processId: ProcessId,
    advocateId: advocateId,
    from: fromName,
    response: "",
    message: "",
    avatar: avatar,
    index: "",
  });
  const defaultGetMessage = [];
  const [getMessage, _setGetMessage] = useState(defaultGetMessage);
  const getMessageRef = useRef(defaultGetMessage);
  const [currentDate, setCurrentDate] = useState("");
  const [show, setshow] = useState(false);
  const [showId, setshowId] = useState("");
  const [response, setResponse] = useState();
  const [loading, setLoading] = useState(false);
  const [chatOpen, setChatOpen] = useState(false);
  const elem = useRef("");
  const resp = useRef("");
  const setGetMessage = newValue => {
    getMessageRef.current = newValue;
    _setGetMessage(newValue);
  };

  // var socket = io(SOCKET_URL); //CONNECTION ESTABLISHED
  useEffect(() => {
    const submitData = { processId: ProcessId };
    // var socket = io(SOCKET_URL);

    socket.emit("joinRoom", ProcessId, advocateId);
    getAllChat(submitData).then(res => {
      // console.log("resz", res);
      if (res.success) {
        setGetMessage(res.data);
        setCurrentDate(res.data[0].msgDate);
      } else {
        setGetMessage([]);
      }
    });
  }, []);
  // useEffect(()=> {
  //   socket.on("typingResponse", data => setTypingStatus(data))
  // }, [socket])
  useEffect(() => {
    // console.log("update in getMessage : ", getMessage);
    // var socket = io(SOCKET_URL);
    socket.on("messages", data => {
      // CHECK DATE AND PUSH ARRAY
      // console.log("messages EVENT :: ", data);

      const checkDate = getMessage[getMessage?.length - 1]?.date === data?.msgDate;
      // console.log("checkDate", checkDate);
      // if (checkDate) {
      setGetMessage([...getMessage, { date: data.msgDate, data: [{ ...data, response: [] }] }]); //FOR STORING MESSAGE
    });
  }, [getMessage]);
  // console.log("getMessage", getMessage[18]);
  useEffect(() => {
    socket.on("response-message", respData => {
      const getMessageData = getMessageRef.current;
      let newData = getMessageData;
      // console.log("DATA NEW SOCKET EVENT :: ", respData);
      // respData.message = respData.response;
      // const dataIndex = data?.index;
      // console.log("dataIndex", respData.index);
      // console.log("GET MESSAGE IN", getMessageData);
      // console.log("GET MESSAGE getMessage", getMessage);
      // console.log(" ELSE --->", getMessageData[respData?.index]?.data[0]?.response);
      let pushData = getMessageData[respData?.index]?.data[0]?.response;
      pushData.push(respData);
      // getMessageData[respData.index].data.response.push(respData);
      // console.log("pushData", pushData);
      getMessageData[respData.index].data[0].response = pushData;
      setGetMessage([...getMessageData]);
    });
  }, [socket]);

  const handleChange = e => {
    setMessage({
      ...message,
      [e.target.name]: e.target.value,
    });
  };
  const handleResponseChange = (e, obj, index) => {
    // console.log("obj", obj);
    setResMessage({
      ...resMessage,
      [e.target.name]: e.target.value,
      message: e.target.value,
      id: obj.id,
      index: index,
    });
  };
  // console.log("resMessage", resMessage);

  const onSubmitMessage = () => {
    const submitMessage = {
      sender: loginUserId,
      processId: ProcessId,
      advocateId: advocateId,
      from: fromName,
      msgTime: "",
      message: elem.current.value,
      response: [],
      avatar: avatar,
    };
    if (submitMessage.message !== "" && submitMessage.message.trim()) {
      socket.emit("messages", submitMessage);
    }
    setChatOpen(!chatOpen);
    setMessage({
      sender: loginUserId,
      processId: ProcessId,
      advocateId: advocateId,
      from: fromName,
      msgTime: "",
      message: "",
      avatar: avatar,
    });
  };

  const onEnter = e => {
    const submitMessage = {
      sender: loginUserId,
      processId: ProcessId,
      advocateId: advocateId,
      from: fromName,
      msgTime: "",
      message: elem.current.value,
      response: [],
      avatar: avatar,
    };
    if (e.key === "Enter" && submitMessage.message.trim()) {
      socket.emit("messages", submitMessage);
      setChatOpen(!chatOpen);
      setMessage({
        sender: loginUserId,
        processId: ProcessId,
        advocateId: advocateId,
        from: fromName,
        message: "",
        avatar: avatar,
      });
    }
  };

  const onEnterResponse = (e, obj, i) => {
    const newResp = {
      id: obj.id,
      sender: loginUserId,
      processId: ProcessId,
      advocateId: advocateId,
      from: fromName,
      response: resp.current.value,
      message: resp.current.value,
      avatar: avatar,
      index: i,
    };

    if (e.key === "Enter" && newResp.response.trim()) {
      setLoading(true);
      // console.log("resMessage", resMessage);
      // var socket = io(SOCKET_URL);
      const submitData = { processId: ProcessId };
      socket.emit("response-message", newResp);

      const getMessageData = getMessage;

      getMessageData[newResp.index].data[0].response.push(newResp);
      setGetMessage([...getMessageData]);
      setResMessage({
        id: "",
        sender: loginUserId,
        processId: ProcessId,
        advocateId: advocateId,
        from: fromName,
        response: "",
        message: "",
        avatar: avatar,
        index: "",
      });
      setshowId("");
      setLoading(false);
    }
  };
  const hideShowInput = (id, i) => {
    setshow(true);
    setshowId(id);
  };

  const onSubmitResponse = (obj, i) => {
    setLoading(true);
    const newResp = {
      id: obj.id,
      sender: loginUserId,
      processId: ProcessId,
      advocateId: advocateId,
      from: fromName,
      response: resp.current.value,
      message: resp.current.value,
      avatar: avatar,
      index: i,
    };
    socket.emit("response-message", newResp);
    //append here
    // console.log("resMessage", newResp);
    // console.log("getMessage", getMessage);
    const getMessageData = getMessage;
    // let pushData = getMessageData[resMessage.index].data[0].response;
    // pushData.push(resMessage);
    getMessageData[newResp.index].data[0].response.push(newResp);
    setGetMessage([...getMessageData]);
    // setResMessage({
    //   id: "",
    //   sender: loginUserId,
    //   processId: ProcessId,
    //   advocateId: advocateId,
    //   from: fromName,
    //   response: "",
    //   avatar: "",
    //   index: "",
    // });

    setshowId("");
    setLoading(false);
  };

  // console.log("getMessage", getMessage);
  // console.log("resMessage", resMessage);
  return (
    <div>
      <Grid container spacing={{ xs: 2, md: 3 }} className={classes.root}>
        <Grid item xs={12} sm={12} md={12}>
          <Card variant="elevation">
            <ScrollToBottom>
              {loading ? (
                <Loader />
              ) : (
                <CardContent sx={{ height: "55vh" }}>
                  {getMessage.length ? (
                    getMessage?.map((obj, i) => (
                      <>
                        <TimeStampCard time={obj.date} />
                        {/* {currentDate !== obj.dateString ? <TimeStampCard time={obj.dateString} /> : () => setCurrentDate(obj.dateString)} */}

                        {obj?.data?.map((obj, indx) => (
                          <Container sx={{ padding: 0 }} key={indx}>
                            <Grid sx={{ marginTop: "0.5rem" }}>
                              <div
                                style={{
                                  border: "1.47059px solid rgba(117, 117, 117, 0.3)",
                                  padding: "0.3rem 1rem",
                                  borderRadius: "14.7059px",
                                  color: "#636C84",
                                }}>
                                <>
                                  <EquipCard data={obj} msgTime={obj.msgTime} />
                                  {obj?.response &&
                                    obj?.response?.map((obj1, indx) => {
                                      return <EquipCard data={obj1} msgTime={obj1.msgTime} />;
                                    })}
                                </>
                                ​{/* <EquipCard data={obj} /> */}
                                <Grid container sx={{ marginBottom: "1rem", cursor: "pointer" }}>
                                  <Grid item xs={3} sm={1} md={1} sx={{}}></Grid>
                                  <Grid item xs={9} sm={11} md={11} sx={{ position: "relative", left: "-2%", display: "flex" }}>
                                    <img src="/assets/images/share.png" />
                                    <Typography sx={{ color: "#0085FF", marginLeft: "1rem" }} onClick={() => hideShowInput(obj.id, i)}>
                                      Responder
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={3} sm={1} md={1}></Grid>
                                  {showId === obj.id && (
                                    <>
                                      <TextField
                                        id="standard-basic"
                                        label="Responder"
                                        variant="standard"
                                        name="response"
                                        sx={{ color: "#0085FF", marginLeft: "1rem" }}
                                        inputRef={resp}
                                        // onChange={e => handleResponseChange(e, obj, i)}
                                        onKeyPress={e => onEnterResponse(e, obj, i)}
                                      />
                                      <InputAdornment
                                        position="end"
                                        sx={{ cursor: "pointer", mt: 5 }}
                                        onClick={() => onSubmitResponse(obj, i)}>
                                        <SendIcon />
                                      </InputAdornment>
                                    </>
                                  )}
                                </Grid>
                              </div>
                            </Grid>
                          </Container>
                        ))}
                      </>
                    ))
                  ) : (
                    <RecordFound label="Iniciar novo bate-papo" />
                  )}

                  {/* <TimeStampCard time={"Segunda - feira, 20 de fev"} />
​
                <Container sx={{ padding: 0 }}>
                  <Grid sx={{ marginTop: "0.5rem" }}>
                    <div
                      style={{
                        border: "1.47059px solid rgba(117, 117, 117, 0.3)",
                        padding: "0.3rem 1rem",
                        borderRadius: "14.7059px",
                        color: "#636C84",
                      }}>
                      <EquipCard />
                      <Grid container sx={{ marginBottom: "1rem", cursor: "pointer" }}>
                        <Grid item xs={3} sm={1} md={1} sx={{}}></Grid>
                        <Grid item xs={9} sm={11} md={11} sx={{ position: "relative", left: "-2%", display: "flex" }}>
                          <img src="/assets/images/share.png" />
                          <Typography sx={{ color: "#0085FF", marginLeft: "1rem" }}>Responder</Typography>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                </Container> */}
                </CardContent>
              )}
            </ScrollToBottom>

            <Container sx={{ padding: 0 }}>
              <Grid sx={{ marginTop: "0.5rem" }}>
                {chatOpen && (
                  <FormControl variant="outlined" fullWidth>
                    <OutlinedInput
                      sx={{ backgroundColor: "#ffffff" }}
                      id="outlined-adornment-weight"
                      // value={message.message}
                      name="message"
                      placeholder="Digite aqui..."
                      // onChange={handleChange}
                      onKeyPress={onEnter}
                      inputRef={elem}
                      autoFocus
                      endAdornment={
                        <InputAdornment position="end" sx={{ cursor: "pointer" }} onClick={onSubmitMessage}>
                          <SendIcon />
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                )}
                {!chatOpen && (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Button variant="contained" onClick={() => setChatOpen(!chatOpen)} sx={{ fontWeight: 800, fontFamily: "Poppins" }}>
                      Iniciar conversa
                    </Button>
                  </div>
                )}
              </Grid>
            </Container>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProp = state => {
  return {
    loginUser: state,
  };
};

export default connect(mapStateToProp)(EquipeTab);
// OLD CODE AS NEW RESPONSE DATE OBJECT
// import React, { useEffect, useState, useRef, useContext } from "react";
// import { Button, Card, CardContent, FormControl, Grid, InputAdornment, OutlinedInput, TextField, Typography } from "@mui/material";
// import { Container } from "@mui/system";
// import EquipCard from "../Processos/EquipCard";
// import SendIcon from "@mui/icons-material/Send";
// import TimeStampCard from "./TimeStampCard";
// import { io } from "socket.io-client";
// import { useLocation } from "react-router-dom";
// import { connect } from "react-redux";
// import ScrollToBottom from "react-scroll-to-bottom";
// import { getAllChat } from "../../helper/API/chat";
// // import { SOCKET_URL } from "../../config";
// // import { SocketContext } from "../../context/socket";
// import Loader from "../Loader";
// // var socket = io(SOCKET_URL);

// const EquipeTab = ({ classes, loginUser, socket }) => {
//   const params = useLocation();
//   // const socket = useContext(SocketContext);

//   const processData = JSON.parse(localStorage.getItem("process"));
//   const loginUserId = loginUser.loginUsers.loginUser?.user?._id;
//   const advocateId = processData.advocateOwner;
//   const ProcessId = processData.id;
//   const fromName = loginUser.loginUsers.loginUser?.user?.name;
//   const avatar = loginUser.loginUsers.loginUser?.user.avatar;

//   const [message, setMessage] = useState({
//     sender: loginUserId,
//     processId: ProcessId,
//     advocateId: advocateId,
//     from: fromName,
//     msgTime: "",
//     message: "",
//     response: [],
//     avatar: avatar,
//   });
//   const [resMessage, setResMessage] = useState({
//     id: "",
//     sender: loginUserId,
//     processId: ProcessId,
//     advocateId: advocateId,
//     from: fromName,
//     response: "",
//     message: "",
//     avatar: avatar,
//     index: "",
//   });
//   const defaultGetMessage = [];
//   const [getMessage, _setGetMessage] = useState(defaultGetMessage);
//   const getMessageRef = useRef(defaultGetMessage);
//   const [currentDate, setCurrentDate] = useState("");
//   const [show, setshow] = useState(false);
//   const [showId, setshowId] = useState("");
//   const [response, setResponse] = useState();
//   const [loading, setLoading] = useState(false);
//   const [chatOpen, setChatOpen] = useState(false);

//   const setGetMessage = newValue => {
//     getMessageRef.current = newValue;
//     _setGetMessage(newValue);
//   };

//   // var socket = io(SOCKET_URL); //CONNECTION ESTABLISHED
//   useEffect(() => {
//     const submitData = { processId: ProcessId };
//     // var socket = io(SOCKET_URL);

//     socket.emit("joinRoom", ProcessId, advocateId);
//     getAllChat(submitData).then(res => {
//       console.log("resz", res);
//       if (res.success) {
//         setGetMessage(res.data);
//         setCurrentDate(res.data[0].msgDate);
//       }
//     });
//   }, []);
//   // useEffect(()=> {
//   //   socket.on("typingResponse", data => setTypingStatus(data))
//   // }, [socket])
//   useEffect(() => {
//     // console.log("update in getMessage : ", getMessage);
//     // var socket = io(SOCKET_URL);
//     // socket.on("messages", data => {
//     //   // CHECK DATE AND PUSH ARRAY
//     //   console.log("messages EVENT :: ", data);
//     //   const checkDate = getMessage[getMessage?.length - 1]?.date === data?.msgDate;
//     //   console.log("checkDate", checkDate);
//     //   if (checkDate) {
//     //     let newArr = getMessage;
//     //     let newData = newArr[newArr?.length - 1]?.data;
//     //     newData.push(data);
//     //     console.log("newArr", newArr);
//     //     setGetMessage([...newArr]); //FOR STORING MESSAGE
//     //   }
//     // });
//   }, [getMessage]);
//   // console.log("getMessage", getMessage[18]);
//   useEffect(() => {
//     socket.on("response-message", respData => {
//       const getMessageData = getMessageRef.current;
//       let newData = getMessageData;
//       console.log("DATA NEW SOCKET EVENT :: ", respData);
//       // respData.message = respData.response;
//       // const dataIndex = data?.index;
//       // console.log("dataIndex", respData.index);
//       console.log("GET MESSAGE IN", getMessageData);
//       console.log("GET MESSAGE getMessage", getMessage);
//       // console.log(" ELSE --->", getMessageData[respData?.index]?.data[0]?.response);
//       let pushData = getMessageData[respData?.index]?.data[0]?.response;
//       pushData.push(respData);
//       // getMessageData[respData.index].data.response.push(respData);
//       console.log("pushData", pushData);
//       getMessageData[respData.index].data[0].response = pushData;
//       setGetMessage([...getMessageData]);
//     });
//   }, [socket]);

//   const handleChange = e => {
//     setMessage({
//       ...message,
//       [e.target.name]: e.target.value,
//     });
//   };
//   const handleResponseChange = (e, obj, index) => {
//     console.log("obj", obj);
//     setResMessage({
//       ...resMessage,
//       [e.target.name]: e.target.value,
//       message: e.target.value,
//       id: obj.id,
//       index: index,
//     });
//   };

//   const onSubmitMessage = () => {
//     if (message.message !== "" && message.message.trim()) {
//       // var socket = io(SOCKET_URL);
//       socket.emit("messages", message);
//     }

//     socket.on("messages", data => {
//       // CHECK DATE AND PUSH ARRAY
//       console.log("messages EVENT :: ", data);

//       const checkDate = getMessage[getMessage?.length - 1]?.date === data?.msgDate;
//       console.log("checkDate", checkDate);
//       if (checkDate) {
//         let newArr = getMessage;
//         let newData = newArr[newArr?.length - 1]?.data;
//         newData.push(data);
//         console.log("newArr", newArr);

//         setGetMessage([...newArr]); //FOR STORING MESSAGE
//       } else {
//         // date not exist
//         // setGetMessage([...getMessage,{date: data?.msgDate,data: data?.data}]);
//       }
//     });

//     setChatOpen(!chatOpen);
//     setMessage({
//       sender: loginUserId,
//       processId: ProcessId,
//       advocateId: advocateId,
//       from: fromName,
//       msgTime: "",
//       message: "",
//       avatar: avatar,
//     });
//   };

//   const onEnter = e => {
//     if (e.key === "Enter" && message.message.trim()) {
//       // var socket = io(SOCKET_URL);
//       socket.emit("messages", message);
//       setChatOpen(!chatOpen);
//       socket.on("messages", data => {
//         // CHECK DATE AND PUSH ARRAY
//         console.log("messages EVENT :: ", data);

//         const checkDate = getMessage[getMessage?.length - 1]?.date === data?.msgDate;
//         console.log("checkDate", checkDate);
//         if (checkDate) {
//           let newArr = getMessage;
//           let newData = newArr[newArr?.length - 1]?.data;
//           newData.push(data);
//           console.log("newArr", newArr);

//           setGetMessage([...newArr]); //FOR STORING MESSAGE
//         }
//       });
//       setMessage({
//         sender: loginUserId,
//         processId: ProcessId,
//         advocateId: advocateId,
//         from: fromName,
//         message: "",
//         avatar: avatar,
//       });
//     }
//   };

//   const onEnterResponse = e => {
//     if (e.key === "Enter" && resMessage.response.trim()) {
//       setLoading(true);
//       // console.log("resMessage", resMessage);
//       // var socket = io(SOCKET_URL);
//       const submitData = { processId: ProcessId };
//       socket.emit("response-message", resMessage);

//       const getMessageData = getMessage;

//       getMessageData[resMessage.index].data[0].response.push(resMessage);
//       setGetMessage([...getMessageData]);
//       setResMessage({
//         id: "",
//         sender: loginUserId,
//         processId: ProcessId,
//         advocateId: advocateId,
//         from: fromName,
//         response: "",
//         message: "",
//         avatar: avatar,
//         index: "",
//       });
//       setshowId("");
//       setLoading(false);
//     }
//   };
//   const hideShowInput = (id, i) => {
//     setshow(true);
//     setshowId(id);
//   };

//   const onSubmitResponse = i => {
//     setLoading(true);
//     const submitData = { processId: ProcessId };
//     // var socket = io(SOCKET_URL);
//     socket.emit("response-message", resMessage);
//     //append here
//     console.log("resMessage", resMessage);
//     console.log("getMessage", getMessage);
//     const getMessageData = getMessage;
//     // let pushData = getMessageData[resMessage.index].data[0].response;
//     // pushData.push(resMessage);
//     getMessageData[resMessage.index].data[0].response.push(resMessage);
//     setGetMessage([...getMessageData]);
//     setResMessage({
//       id: "",
//       sender: loginUserId,
//       processId: ProcessId,
//       advocateId: advocateId,
//       from: fromName,
//       response: "",
//       avatar: "",
//       index: "",
//     });

//     setshowId("");
//     setLoading(false);
//   };

//   console.log("getMessage", getMessage);
//   // console.log("resMessage", resMessage);
//   return (
//     <div>
//       <Grid container spacing={{ xs: 2, md: 3 }} className={classes.root}>
//         <Grid item xs={12} sm={12} md={12}>
//           <Card variant="elevation">
//             <ScrollToBottom>
//               {loading ? (
//                 <Loader />
//               ) : (
//                 <CardContent sx={{ height: "55vh" }}>
//                   {getMessage?.map((obj, i) => (
//                     <>
//                       <TimeStampCard time={obj.date} />
//                       {/* {currentDate !== obj.dateString ? <TimeStampCard time={obj.dateString} /> : () => setCurrentDate(obj.dateString)} */}
//                       {/* {JSON.stringify(obj, null, 2)} */}
//                       {obj?.data?.map((obj, indx) => (
//                         <Container sx={{ padding: 0 }} key={indx}>
//                           <Grid sx={{ marginTop: "0.5rem" }}>
//                             <div
//                               style={{
//                                 border: "1.47059px solid rgba(117, 117, 117, 0.3)",
//                                 padding: "0.3rem 1rem",
//                                 borderRadius: "14.7059px",
//                                 color: "#636C84",
//                               }}>
//                               <>
//                                 <EquipCard data={obj} msgTime={obj.msgTime} />
//                                 {obj?.response &&
//                                   obj?.response?.map((obj1, indx) => {
//                                     return <EquipCard data={obj1} msgTime={obj1.msgTime} />;
//                                   })}
//                               </>
//                               ​{/* <EquipCard data={obj} /> */}
//                               <Grid container sx={{ marginBottom: "1rem", cursor: "pointer" }}>
//                                 <Grid item xs={3} sm={1} md={1} sx={{}}></Grid>
//                                 <Grid item xs={9} sm={11} md={11} sx={{ position: "relative", left: "-2%", display: "flex" }}>
//                                   <img src="/assets/images/share.png" />
//                                   <Typography sx={{ color: "#0085FF", marginLeft: "1rem" }} onClick={() => hideShowInput(obj.id, i)}>
//                                     Responder
//                                   </Typography>
//                                 </Grid>
//                                 <Grid item xs={3} sm={1} md={1}></Grid>
//                                 {showId === obj.id && (
//                                   <>
//                                     <TextField
//                                       id="standard-basic"
//                                       label="Responder"
//                                       variant="standard"
//                                       name="response"
//                                       sx={{ color: "#0085FF", marginLeft: "1rem" }}
//                                       onChange={e => handleResponseChange(e, obj, i)}
//                                       onKeyPress={onEnterResponse}
//                                     />
//                                     <InputAdornment position="end" sx={{ cursor: "pointer", mt: 5 }} onClick={onSubmitResponse}>
//                                       <SendIcon />
//                                     </InputAdornment>
//                                   </>
//                                 )}
//                               </Grid>
//                             </div>
//                           </Grid>
//                         </Container>
//                       ))}
//                     </>
//                   ))}

//                   {/* <TimeStampCard time={"Segunda - feira, 20 de fev"} />
// ​
//                 <Container sx={{ padding: 0 }}>
//                   <Grid sx={{ marginTop: "0.5rem" }}>
//                     <div
//                       style={{
//                         border: "1.47059px solid rgba(117, 117, 117, 0.3)",
//                         padding: "0.3rem 1rem",
//                         borderRadius: "14.7059px",
//                         color: "#636C84",
//                       }}>
//                       <EquipCard />
//                       <Grid container sx={{ marginBottom: "1rem", cursor: "pointer" }}>
//                         <Grid item xs={3} sm={1} md={1} sx={{}}></Grid>
//                         <Grid item xs={9} sm={11} md={11} sx={{ position: "relative", left: "-2%", display: "flex" }}>
//                           <img src="/assets/images/share.png" />
//                           <Typography sx={{ color: "#0085FF", marginLeft: "1rem" }}>Responder</Typography>
//                         </Grid>
//                       </Grid>
//                     </div>
//                   </Grid>
//                 </Container> */}
//                 </CardContent>
//               )}
//             </ScrollToBottom>

//             <Container sx={{ padding: 0 }}>
//               <Grid sx={{ marginTop: "0.5rem" }}>
//                 {chatOpen && (
//                   <FormControl variant="outlined" fullWidth>
//                     <OutlinedInput
//                       sx={{ backgroundColor: "#ffffff" }}
//                       id="outlined-adornment-weight"
//                       value={message.message}
//                       name="message"
//                       placeholder="Digite aqui..."
//                       onChange={handleChange}
//                       onKeyPress={onEnter}
//                       endAdornment={
//                         <InputAdornment position="end" sx={{ cursor: "pointer" }} onClick={onSubmitMessage}>
//                           <SendIcon />
//                         </InputAdornment>
//                       }
//                     />
//                   </FormControl>
//                 )}
//                 {!chatOpen && (
//                   <div style={{ display: "flex", justifyContent: "center" }}>
//                     <Button variant="contained" onClick={() => setChatOpen(!chatOpen)}>
//                       Iniciar conversa
//                     </Button>
//                   </div>
//                 )}
//               </Grid>
//             </Container>
//           </Card>
//         </Grid>
//       </Grid>
//     </div>
//   );
// };

// const mapStateToProp = state => {
//   return {
//     loginUser: state,
//   };
// };

// export default connect(mapStateToProp)(EquipeTab);
