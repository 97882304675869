import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { makeStyles } from "@material-ui/core/styles";
import { experimentalStyled as styled } from "@mui/material/styles";
import { Avatar, Card, CardContent, CircularProgress, Grid, Typography } from "@mui/material";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

const Img = styled("img")({
  display: "block",
  //   maxWidth: "40%",
  //   maxHeight: "100%",
});

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    borderRadius: 10,
    marginBottom: "1rem",

    // maxWidth: 500,
    "& .MuiTab-textColorSecondary.Mui-selected": {
      color: "#ffff",
      zIndex: 60,
    },
    "& .MuiTabs-indicator": {
      height: "100% !important",
      borderRadius: 160,

      background: "linear-gradient(180deg, #FE2D66 0%, #A03E88 61.46%, #734699 91.15%) !important",
    },
    "& .MuiTab-wrapper": {
      textTransform: "capitalize",
      fontSize: 16,
      fontWeight: "bold",
    },

    "& .MuiInputBase-root": {
      padding: "5px 5px !important",
      borderRadius: "13px",
    },
    "& .MuiPaper-elevation": {
      boxShadow: "unset !important",
    },
    "&::placeholder": {
      textOverflow: "ellipsis !important",
      color: "#000",
    },
    "& .MuiFormLabel-root": {
      color: "#000",
    },
  },
  input: {
    "&::placeholder": {
      textOverflow: "ellipsis !important",
      color: "#000",
    },
  },
  actionContainer: {
    background: "#D6D9FF",
    borderTopRightRadius: "13px",
    borderBottomRightRadius: "13px",
    // padding: "8px 15px",
  },
  title: {
    fontFamily: "Poppins",
    color: "#FFFFFF",
    // border: "2px solid red"
  },
  subtitle: {
    fontFamily: "Poppins",
    color: "#FFFFFF",
    // border: "2px solid red"
  },

  card: {
    // marginTop: "2rem",
    // marginBottom: "1rem",
    boxShadow: "unset !important",
    borderRadius: 11,
  },
  userName: {
    fontFamily: "Poppins",
    fontWeight: 900,
    fontSize: "26px",
    lineHeight: "39px",
    color: "#2E1E42",
  },
  details: {
    fontFamily: "Roboto",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "19px",
    color: "#333333",
  },
  detailsNames: {
    fontFamily: "Roboto",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "16px",
    paddingLeft: "6px",
    color: "#333333",
  },
  advogadoHeading: {
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "27px",
    color: "#333333",
  },
  description: {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#11142D",
    padding: "10px",
  },
});

const AdvogadosProfile = ({ profileData, loading }) => {
  const classes = useStyles();
  let navigate = useNavigate();

  const [data, setData] = useState(profileData);

  useEffect(() => {
    setData(profileData);
  }, [profileData]);

  // console.log("loading", loading);
  console.log("profileData", profileData);

  function findUrlByKeyword(urls, keyword) {
    for (let i = 0; i < urls.length; i++) {
      if (urls[i].includes(keyword)) {
        return urls[i];
      }
    }
    return false; // return null if no matching URL is found
  }

  return (
    <Box sx={{ flexGrow: 1, background: "#F8F5FA" }} px={8} py={4}>
      <>
        <Typography
          onClick={() => navigate(-1)}
          sx={{
            color: "#5031A9",
          }}>
          Voltar
        </Typography>
        {loading ? (
          <Box sx={{ display: "flex", justifyContent: "center", height: "100%" }}>
            <CircularProgress color="primary" />
          </Box>
        ) : (
          <Grid container spacing={{ xs: 2, md: 3 }} px={18} className={classes.root}>
            <Grid item xs={12} sm={12} md={12}>
              <Card variant="elevation">
                <CardContent>
                  <Card variant="elevation" className={classes.card}>
                    {data.map((obj, indx) => (
                      <CardContent mnb={5} key={indx}>
                        <Grid container>
                          <Grid item md={12} lg={12} display={"flex"} justifyContent={"center"}>
                            <Avatar
                              alt="Remy Sharp"
                              src={obj?.avatar}
                              sx={{
                                width: "100px",
                                height: "100px",
                              }}
                            />
                          </Grid>
                          <Grid item md={12} lg={12} display={"flex"} justifyContent={"center"}>
                            <div className={classes.userName}>{obj?.name}</div>
                          </Grid>
                          <Grid item md={12} lg={12} display={"flex"} justifyContent={"center"}>
                            <Img
                              alt="Delete"
                              src="/assets/images/phone.png"
                              width={"20px"}
                              height={"20px"}
                              sx={{
                                maxWidth: "20px !important",
                                maxHeight: "20px !important",
                                posotion: "absolute",
                                background: "#fff",
                                padding: "8px",
                                borderRadius: "13px",
                              }}
                            />
                            <Img
                              alt="Delete"
                              src="/assets/images/mail.png"
                              width={"20px"}
                              height={"20px"}
                              sx={{
                                maxWidth: "20px !important",
                                maxHeight: "20px !important",
                                posotion: "absolute",
                                background: "#fff",
                                padding: "8px",
                                borderRadius: "13px",
                              }}
                            />
                          </Grid>

                          <Grid item sm={4} md={4} mt={2}>
                            <div>
                              <span className={classes.details}>Nome:</span>
                              <span className={classes.detailsNames}>{obj?.name}</span>
                            </div>
                          </Grid>
                          <Grid item sm={4} md={4} mt={2}>
                            <div>
                              <span className={classes.details}>E-mail:</span>
                              <span className={classes.detailsNames}>{obj?.email}</span>
                            </div>
                          </Grid>
                          <Grid item sm={4} md={4} mt={2}>
                            <div>
                              <span className={classes.details}>Telefone:</span>
                              <span className={classes.detailsNames}>{obj?.telephone}</span>
                            </div>
                          </Grid>
                          <Grid item sm={4} md={4} mt={2}>
                            <div>
                              <span className={classes.details}>Área:</span>
                              <span className={classes.detailsNames}>{obj?.occupationArea}</span>
                            </div>
                          </Grid>
                          <Grid item sm={4} md={4} mt={2}>
                            <div>
                              <span className={classes.details}>Código OAB:</span>
                              <span className={classes.detailsNames}>{obj?.oabCode}</span>
                            </div>
                          </Grid>
                          <Grid item sm={4} md={4} mt={2}>
                            <div>
                              <span className={classes.details}>UF</span>
                              <span className={classes.detailsNames}>{obj?.ufCode}</span>
                            </div>
                          </Grid>
                        </Grid>

                        <Grid container mt={5}>
                          <Grid item xs={12} sm={12} md={12} lg={12} mb={2}>
                            <span className={classes.advogadoHeading}>
                              Descrição do {obj.gender === "male" ? "advogado" : obj.gender === "female" ? "advogada" : "profissional"}
                            </span>
                          </Grid>
                          <Grid item xs={11} sm={11} md={11} lg={11} border={"1px solid #DBD7F4"} borderRadius={"10px"}>
                            <p className={classes.description}>{obj?.selfDesc}</p>
                          </Grid>
                        </Grid>
                        <Grid container mt={5}>
                          <Grid item md={12} display={"flex"} justifyContent={"center"}>
                            {findUrlByKeyword(obj?.socialLink, "facebook") && (
                              <a href={findUrlByKeyword(obj?.socialLink, "facebook")} target="_blank">
                                <Img
                                  alt="Delete"
                                  src="/assets/images/logos_facebook.png"
                                  width={50}
                                  height={50}
                                  sx={{
                                    maxWidth: "30px !important",
                                    maxHeight: "30px !important",

                                    background: "#fff",
                                    padding: "8px",
                                    cursor: "pointer",
                                  }}
                                />
                              </a>
                            )}
                            {findUrlByKeyword(obj?.socialLink, "linkedin") && (
                              <a href={findUrlByKeyword(obj?.socialLink, "linkedin")} target="_blank">
                                <Img
                                  alt="Delete"
                                  src="/assets/images/logos_linkedin.png"
                                  width={50}
                                  height={50}
                                  sx={{
                                    maxWidth: "30px !important",
                                    maxHeight: "30px !important",

                                    background: "#fff",
                                    padding: "8px",
                                    cursor: "pointer",
                                  }}
                                />
                              </a>
                            )}
                            {findUrlByKeyword(obj?.socialLink, "twitter") && (
                              <a href={findUrlByKeyword(obj?.socialLink, "twitter")} target="_blank">
                                <Img
                                  alt="Delete"
                                  src="/assets/images/logos_twitter.png"
                                  width={50}
                                  height={50}
                                  sx={{
                                    maxWidth: "30px !important",
                                    maxHeight: "30px !important",

                                    background: "#fff",
                                    padding: "8px",
                                    cursor: "pointer",
                                  }}
                                />
                              </a>
                            )}
                            {findUrlByKeyword(obj?.socialLink, "instagram") && (
                              <a href={findUrlByKeyword(obj?.socialLink, "instagram")} target="_blank">
                                <Img
                                  alt="Delete"
                                  src="/assets/images/logos_instagram.png"
                                  width={50}
                                  height={50}
                                  sx={{
                                    maxWidth: "30px !important",
                                    maxHeight: "30px !important",
                                    background: "#fff",
                                    padding: "8px",
                                    cursor: "pointer",
                                  }}
                                />
                              </a>
                            )}
                            {findUrlByKeyword(obj?.socialLink, "youtube") && (
                              <a href={findUrlByKeyword(obj?.socialLink, "youtube")} target="_blank">
                                <Img
                                  alt="Delete"
                                  src="/assets/images/logos_youtube.png"
                                  width={50}
                                  height={50}
                                  sx={{
                                    maxWidth: "30px !important",
                                    maxHeight: "30px !important",
                                    background: "#fff",
                                    padding: "8px",
                                    cursor: "pointer",
                                  }}
                                />
                              </a>
                            )}
                          </Grid>
                        </Grid>
                      </CardContent>
                    ))}
                  </Card>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        )}
      </>
    </Box>
  );
};

const mapStateToProp = state => {
  // console.log("state", state);
  return {
    profileData: state?.profileReducer?.profileData,
    loading: state?.profileReducer?.processing,
  };
};

export default connect(mapStateToProp)(AdvogadosProfile);
