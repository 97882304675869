export const LOGIN_USER_SUCCESSFUL = "LOGIN_USER_SUCCESSFUL";

export const SET_ACCESS_TOKEN = "SET_ACCESS_TOKEN";

export const LOGIN_PROCESSING = "LOGIN_PROCESSING";

export const STOP_LOGIN_PROCESSING = "STOP_LOGIN_PROCESSING";

export const LOGOUT_SUCCESSFUL = "LOGOUT_SUCCESSFUL";
export const LOGIN_USER_LOCAL_DATA = "LOGIN_USER_LOCAL_DATA";
