import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "./useLocalStorage";
import * as loginAction from "../redux/login/action";
import { toast } from "react-toastify";
import * as loginPlanResponse from "../redux/storeLoginResponse/action.js";

const AuthContext = createContext();

const AuthProvider = ({ children, loginAdvocate, userData, loginLocalData, setLoginPlanResponse }) => {
  const [user, setUser] = useLocalStorage("user", null);
  const [newUser, setNewUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (userData?.data?.isPlanPurchased) {
      setUser(userData.data);
    }
  }, [userData]);

  // console.log("login data", userData);
  // console.log("newUser", newUser);
  const login = async data => {
    // console.log("res in auth", data);
    loginAdvocate(data).then(res => {
      if (res.success) {
        toast.success(res.message);
        setLoginPlanResponse(res);
        setNewUser(data);
        if (res.data.user.isOwner && res.data.user.isTrial == false && res.data.user.isSubscribed == false) {
          navigate("/plan", { replace: true });
          loginLocalData({ ...data, isPlanPurchased: false });
        } else {
          // setUser(userData.data);
          // loginLocalData(data);
          setUser(userData.data);
          loginLocalData({ ...data, isPlanPurchased: true });
          navigate("/home", { replace: true });
        }
      } else {
        toast.error(res.message);
      }
    });
    // setUser(data);
    // navigate("/home", { replace: true });
  };
  const loginSocialAuth = async data => {
    // console.log("login data", data);
    // console.log("loginuserData", userData);

    if (data?.loginUser?.user?.isOwner && data?.loginUser?.user?.isTrial == false && data?.loginUser?.user?.isSubscribed == false) {
      navigate("/plan", { replace: true });
    } else {
      setUser(data?.data);
      loginLocalData({ data });
      navigate("/home", { replace: true });
    }
  };

  const newDataSet = async data => {
    // setUser(newUser);
    // loginLocalData(newUser);

    setUser(userData.data);
    loginLocalData({ ...newUser, isPlanPurchased: true });
  };
  const logout = () => {
    // console.log("called");
    setUser(null);
    navigate("/", { replace: true });
    document.cookie.split(";").forEach(function (c) {
      document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";domain=.example.com;path=/");
    });
  };

  const value = useMemo(
    () => ({
      user,
      login,
      logout,
      loginSocialAuth,
      newDataSet,
    }),
    [user],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

const mapDispatchToProp = dispatch => {
  return {
    loginAdvocate: loginData => dispatch(loginAction.loginUser(loginData)),
    loginLocalData: data => dispatch(loginAction.setData(data)),
    setLoginPlanResponse: data => dispatch(loginPlanResponse.setLoginResponse(data)),
  };
};
const mapStateToProp = state => {
  return {
    userData: state.loginUsers,
  };
};

export default connect(mapStateToProp, mapDispatchToProp)(AuthProvider);

export const useAuth = () => {
  return useContext(AuthContext);
};
