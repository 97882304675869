import { GET_PROFILE_SUCCESSFUL, PROFILE_PROCESSING, STOP_PROFILE_PROCESSING } from "./type";

const defaultReducer = {
  processing: false,
  profileData: null,
};

const profileReducer = (state = defaultReducer, action) => {
  switch (action.type) {
    case GET_PROFILE_SUCCESSFUL:
      return {
        ...state,
        processing: false,
        profileData: action.payload,
      };

    case PROFILE_PROCESSING:
      return {
        ...state,
        processing: false,
      };

    case STOP_PROFILE_PROCESSING:
      return {
        ...state,
        processing: false,
      };

    default:
      return state;
  }
};

export default profileReducer;
