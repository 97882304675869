import * as React from "react";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import { experimentalStyled as styled } from "@mui/material/styles";
import CancelIcon from "@mui/icons-material/Cancel";

const Img = styled("img")({
  display: "block",
  maxWidth: "90px",
});
const useStyles = makeStyles({
  root: {
    // margin: "2% 0",
    borderRadius: "13px !important",
    display: "flex",
    alignItems: "center",
    width: "99%",
    padding: "6px 13px",
    boxShadow: "unset !important",
    input: {
      "&::placeholder": {
        textOverflow: "ellipsis !important",
        color: "blue",
      },
    },
    "& .MuiButtonBase-root-MuiIconButton-root": {
      padding: "0 !important",
    },
    "& .MuiInputBase-root": {
      padding: "0 5px !important",
      borderRadius: "13px",
    },

    "& .MuiPaper-elevation": {
      boxShadow: "unset !important",
    },
  },
  customTextField: {
    "& input::placeholder": {
      fontSize: "16px",
      color: "#77838F",
      letterSpacing: "1px",
    },
  },
});
const CustomizedSearchBar = ({ placeholder, onChange, handleSubmitSearch }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.root} component="form">
      <InputBase
        classes={{ root: classes.customTextField }}
        sx={{ ml: 1, flex: 1 }}
        placeholder={placeholder ? placeholder : "Buscar cliente"}
        onKeyPress={e => {
          if (e.key === "Enter") {
            handleSubmitSearch();
            e.preventDefault();
          }
        }}
        // inputProps={{ "aria-label": "Buscar cliente" }}
        onChange={onChange}
      />
      <IconButton type="button" sx={{ padding: "0 !important", mr: "15px" }} aria-label="search" onClick={handleSubmitSearch}>
        <Img alt="complex" src="/assets/images/search_icon.svg" />
        {/* <CancelIcon /> */}
      </IconButton>
    </Paper>
  );
};
export default CustomizedSearchBar;
