import React, { useState, useEffect } from "react";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import styled from "styled-components";
import moment from "moment";
import momentTz from "moment-timezone";
import config from "../../config";
import "@mobiscroll/react/dist/css/mobiscroll.min.css";
import { Datepicker, setOptions, CalendarPrev, CalendarNav, CalendarNext } from "@mobiscroll/react";
import { Box } from "@mui/material";

// setOptions({
//   theme: "ios",
//   themeVariant: "light",
// });
const StyledSlider = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const calendarHeader = () => {
  return (
    <React.Fragment className="mbsc-justify-content-center">
      <CalendarPrev className="custom-prev" />
      <CalendarNav className="custom-nav" />
      <CalendarNext className="custom-next" />
    </React.Fragment>
  );
};
const Slider = ({}) => {
  const [totalDays, setTotalDays] = useState(0);
  const currentMonth = moment().get("month");

  const [current, setCurrent] = useState(currentMonth);
  const months = [
    {
      count: 0,
      name: "Janurary",
    },
    {
      count: 1,
      name: "Februay",
    },
    {
      count: 2,
      name: "March",
    },
    {
      count: 3,
      name: "April",
    },
    {
      count: 4,
      name: "May",
    },
    {
      count: 5,
      name: "June",
    },
    {
      count: 6,
      name: "July",
    },
    {
      count: 7,
      name: "August",
    },
    {
      count: 8,
      name: "September",
    },
    {
      count: 9,
      name: "October",
    },
    {
      count: 10,
      name: "November",
    },
    {
      count: 11,
      name: "December",
    },
  ];
  const length = months.length;

  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  };

  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
  };
  const week6 = Array.from(Array(6).keys());
  useEffect(() => {
    // console.log("current", current);
    const currMonth = moment(current, "M").format("MMMM");
    // console.log("currMonth", currMonth);
    setTotalDays(moment(current, "M").daysInMonth());
    const firstWeekDay = Array.from(Array(6).keys());
    // console.log("Array ::: ", moment(current, "M").daysInMonth());
  }, [current]);
  return (
    <>
      <StyledSlider>
        {/* <Box>
          <Box display="flex" justifyContent="center" alignItems={"center"} m={3}>
            <ArrowBackIosNewIcon className="left-arrow" onClick={prevSlide} sx={{ cursor: "pointer" }} />
            {months.map((slide, index) => {
              return <div key={index}>{index === current && <b> {slide.name}</b>}</div>;
            })}
            <ArrowForwardIosIcon className="right-arrow" onClick={nextSlide} sx={{ cursor: "pointer" }} />
          </Box>
          <Box display="flex" flexDirection="column" alignItems={"center"} m={3}>
            <Box display="flex" justifyContent="space-between" alignItems={"center"}>
              <Box h={1} w={2} sx={{ background: "red" }}>
                s
              </Box>
              <Box m={1} p={2} sx={{ background: "red" }}>
                m
              </Box>
              <Box m={1} p={2} sx={{ background: "red" }}>
                t
              </Box>
              <Box m={1} p={2} sx={{ background: "red" }}>
                w
              </Box>
              <Box m={1} p={2} sx={{ background: "red" }}>
                t
              </Box>
              <Box m={1} p={2} sx={{ background: "red" }}>
                f
              </Box>
              <Box m={1} p={2} sx={{ background: "red" }}>
                s
              </Box>
            </Box>
            <Box display="flex" justifyContent="space-between" alignItems={"center"}>
              {week6.map((obj, i) => (
                <Box m={1} p={2} sx={{ background: "red" }}>
                  {i + 1}
                </Box>
              ))}
             
            </Box>
          </Box>
        </Box> */}
      </StyledSlider>
      <div>
        <Datepicker controls={["calendar"]} renderCalendarHeader={calendarHeader} display="inline" calendarType="week" calendarSize={1} />
        {/* <Datepicker controls={["calendar"]} display="inline" calendarType="week" calendarSize={2} />
        <Datepicker controls={["calendar"]} display="inline" calendarType="week" calendarSize={3} /> */}
      </div>
    </>
  );
};

export default Slider;
