import React from "react";
import { Paper, Tab, Tabs } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    borderRadius: 160,
    marginBottom: "2rem",
    "& .MuiTab-textColorSecondary.Mui-selected": {
      color: "#ffff",
      zIndex: 60,
    },
    "& .MuiTabs-indicator": {
      height: "100% !important",
      borderRadius: 160,

      background: "linear-gradient(180deg, #FE2D66 0%, #A03E88 61.46%, #734699 91.15%) !important",
    },
    "& .MuiTab-wrapper": {
      textTransform: "capitalize",
      fontSize: 16,
      fontWeight: "bold",
    },
    "& .MuiPaper-elevation1": {
      boxShadow: "unset !important",
    },
    "& .MuiGrid-root": {
      "& .MuiPaper-root": {
        "& .MuiCardContent-root": {
          padding: "0 0 5rem 0 !important",
        },
      },
      "& .MuiContainer-root": {
        padding: 5,
      },
    },
  },
});

const CustomTabPanel = ({ tabs, handleChange, value }) => {
  const classes = useStyles();
  return (
    <Paper square className={classes.root}>
      <Tabs
        value={value}
        onChange={handleChange}
        variant="fullWidth"
        indicatorColor="secondary"
        textColor="secondary"
        aria-label="icon label tabs example">
        {tabs.map((val, i) => (
          <Tab label={val} value={i} key={i} />
        ))}
      </Tabs>
    </Paper>
  );
};

export default CustomTabPanel;
