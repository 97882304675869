import { AfterAuthApi } from "..";
import {
  ADD_ADVOCATE,
  GET_ADVOCATE,
  GET_ADVOCATE_DETAIL,
  EDIT_ADVOCATE,
  DELETE_ADVOCATE,
  CALENDAR_TASK,
  ALERT_ADVOCATE_TASK,
  GET_PROFILE,
  DELETE_ADVOCATE_WITH_EMAIL,
  DELETE_ADVOCATE_WITH_OTP,
} from "../url";

// add advocate
export const addAdvocate = (formField, occupationId, formValues, image, gender) => {
  // console.log("image", image);
  const formData = new FormData();
  formData.append("name", formField.name);
  formData.append("email", formField.email);
  formData.append("telephone", formField.telephone);
  formData.append("oabCode", formField.oabCode);
  formData.append("ufCode", formField.ufCode);
  formData.append("selfDesc", formField.selfDesc);

  formData.append("occupationArea", occupationId);
  formData.append("socialLink", formValues);
  formData.append("avatar", image);
  formData.append("gender", gender);
  formData.append("area", "");
  formData.append("officeId", "");
  formData.append("mobileNumber", "");

  return new Promise((resolve, reject) => {
    AfterAuthApi(ADD_ADVOCATE, "post", formData)
      .then(res => {
        resolve(res);
      })
      .catch(reject);
  });
};

//all advocate list
export const editAdvocate = (formField, occupationId, formValues, image, gender) => {
  const formData = new FormData();

  {
    formField.id && formData.append("id", formField.id);
  }
  formData.append("name", formField.name);
  formData.append("email", formField.email);
  formData.append("telephone", formField.telephone);
  formData.append("oabCode", formField.oabCode);
  formData.append("ufCode", formField.ufCode);
  formData.append("selfDesc", formField.selfDesc);

  formData.append("occupationArea", occupationId);
  formData.append("socialLink", formValues);
  formData.append("avatar", image);
  formData.append("gender", gender);
  formData.append("area", "");
  formData.append("officeId", "");
  formData.append("mobileNumber", "");

  // console.log("formData", formData);
  return new Promise((resolve, reject) => {
    AfterAuthApi(EDIT_ADVOCATE, "post", formData)
      .then(res => {
        resolve(res.data);
      })
      .catch(reject);
  });
};

//all advocate list
export const getAdvocate = submitData => {
  // console.log("submitData", submitData);
  return new Promise((resolve, reject) => {
    AfterAuthApi(GET_ADVOCATE, "get", submitData)
      .then(res => {
        // console.log("res ::api", res);
        resolve(res.data);
      })
      .catch(reject);
  });
};

//get single advocate detail
export const getAdvocateDetail = submitData => {
  // console.log("submitData", submitData);
  return new Promise((resolve, reject) => {
    AfterAuthApi(GET_ADVOCATE_DETAIL, "post", submitData)
      .then(res => {
        resolve(res);
      })
      .catch(reject);
  });
};

//get single advocate detail
export const deleteAdvocate = submitData => {
  // console.log("submitData", submitData);
  return new Promise((resolve, reject) => {
    AfterAuthApi(DELETE_ADVOCATE, "post", submitData)
      .then(res => {
        resolve(res.data);
      })
      .catch(reject);
  });
};

//get single advocate detail
export const getTaskByMonth = submitData => {
  // console.log("submitData", submitData);
  return new Promise((resolve, reject) => {
    AfterAuthApi(CALENDAR_TASK, "post", submitData)
      .then(res => {
        resolve(res.data);
      })
      .catch(reject);
  });
};

//get single advocate detail
export const alertAdvocateTask = submitData => {
  // console.log("submitData", submitData);
  return new Promise((resolve, reject) => {
    AfterAuthApi(ALERT_ADVOCATE_TASK, "post", submitData)
      .then(res => {
        resolve(res.data);
      })
      .catch(reject);
  });
};

//get single advocate detail
export const getProfile = submitData => {
  // console.log("submitData", submitData);
  return new Promise((resolve, reject) => {
    AfterAuthApi(GET_PROFILE, "post", submitData)
      .then(res => {
        resolve(res.data);
      })
      .catch(reject);
  });
};

//Delete advocate with email
export const deleteAdvocateWithEmail = () => {
  // console.log("submitData", submitData);
  return new Promise((resolve, reject) => {
    AfterAuthApi(DELETE_ADVOCATE_WITH_EMAIL, "post")
      .then(res => {
        resolve(res.data);
      })
      .catch(reject);
  });
};

//Delete advocate with password
export const deleteAdvocateWithOtp = submitData => {
  // console.log("submitData", submitData);
  return new Promise((resolve, reject) => {
    AfterAuthApi(DELETE_ADVOCATE_WITH_OTP, "post", submitData)
      .then(res => {
        resolve(res.data);
      })
      .catch(reject);
  });
};
