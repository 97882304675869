import { GET_CHAT_DETAIL, GET_ALL_CHAT, GET_PERSONAL_CHAT_HISTORY, ADD_CHAT_ATTACHMENT, GET_CHAT_ATTACHMENT } from "../url";
import { AfterAuthApi } from "..";

export const getChatDetail = () => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(GET_CHAT_DETAIL, "post")
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject();
      });
  });
};

export const getAllChat = submitData => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(GET_ALL_CHAT, "post", submitData)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject();
      });
  });
};

export const getPersonalChatHistory = submitData => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(GET_PERSONAL_CHAT_HISTORY, "post", submitData)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject();
      });
  });
};

export const addChatAttachment = submitData => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(ADD_CHAT_ATTACHMENT, "post", submitData)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject();
      });
  });
};

export const getChatAttachment = submitData => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(GET_CHAT_ATTACHMENT, "post", submitData)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject();
      });
  });
};
