import { AfterAuthApi } from "..";
import { GET_OCCUPATION_TYPE, CONFIG_OFFICE, GET_SINGLE_OFFICE_DETAILS, EDIT_SINGLE_OFFICE, GET_NOTE_TYPE, GET_OFFICE_TYPE } from "../url";

export const getOccupationType = () => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(GET_OCCUPATION_TYPE, "get")
      .then(res => {
        resolve(res);
      })
      .catch(reject);
  });
};

export const configOffice = submitData => {
  // console.log("submitData", submitData);
  const formData = new FormData();
  formData.append("name", submitData.name);
  formData.append("email", submitData.email);
  formData.append("city", submitData.city);
  formData.append("district", submitData.district);
  formData.append("identityId", submitData.identityId);
  formData.append("occupationArea", submitData.occupationArea);
  formData.append("officeDesc", submitData.officeDesc);
  formData.append("postal", submitData.postal);
  formData.append("room", submitData.room);
  formData.append("socialLink", submitData.socialLink);
  formData.append("socialReason", submitData.socialReason);
  formData.append("state", submitData.state);
  formData.append("street", submitData.street);
  formData.append("telephone", submitData.telephone);
  formData.append("website", submitData.website);
  formData.append("avatar", submitData.images);
  formData.append("number", submitData.number);
  formData.append("cep", submitData.cep);
  formData.append("area", "");
  formData.append("mobileNumber", "");

  return new Promise((resolve, reject) => {
    AfterAuthApi(CONFIG_OFFICE, "post", formData)
      .then(res => {
        resolve(res.data);
      })
      .catch(reject);
  });
};

export const getSingleOfficeDetail = () => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(GET_SINGLE_OFFICE_DETAILS, "post")
      .then(res => {
        resolve(res.data.data);
      })
      .catch(reject);
  });
};

export const editOffice = submitData => {
  const formData = new FormData();
  formData.append("name", submitData.name);
  formData.append("officeId", submitData.id);
  formData.append("email", submitData.email);
  formData.append("city", submitData.city);
  formData.append("district", submitData.district);
  formData.append("identityId", submitData.identityId);
  formData.append("occupationArea", submitData.occupationArea);
  formData.append("officeDesc", submitData.officeDesc);
  formData.append("postal", submitData.postal);
  formData.append("room", submitData.room);
  formData.append("socialLink", submitData.socialLink);
  formData.append("socialReason", submitData.socialReason);
  formData.append("state", submitData.state);
  formData.append("street", submitData.street);
  formData.append("telephone", submitData.telephone);
  formData.append("website", submitData.website);
  formData.append("avatar", submitData.images);
  formData.append("number", submitData.number);
  formData.append("cep", submitData.cep);
  formData.append("area", "");
  formData.append("mobileNumber", "");
  // console.log("submitData", submitData);
  return new Promise((resolve, reject) => {
    AfterAuthApi(EDIT_SINGLE_OFFICE, "post", formData)
      .then(res => {
        resolve(res.data);
      })
      .catch(reject);
  });
};

export const getOfficeType = () => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(GET_OFFICE_TYPE, "get")
      .then(res => {
        resolve(res);
      })
      .catch(reject);
  });
};
