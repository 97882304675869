// AUTH APIs
export const LOGIN_USER = "advocate-auth/login";
export const FORGOT_PASSWORD = "advocate-auth/forgot-password";
export const VERIFY_FORGOT_PASSWORD = "advocate-auth/verify-and-update";
export const CHECK_PROVIDER_ID = "advocate-auth/check-providerId";
export const GET_PROFILE = "advocate/attorney/get-profile";
export const REGISTER_ADVOCATE = "advocate-auth/register";
export const SOCIAL_AUTH = "advocate-auth/social";
export const GET_PLAN = "advocate/plan/get";

// AFTER AUTH URL

export const GET_OCCUPATION_TYPE = "public/get-occupation-type";

// OFFICE APIs
export const GET_OFFICE_TYPE = "public/get-office-type";
export const CONFIG_OFFICE = "advocate/office/config";
export const GET_SINGLE_OFFICE_DETAILS = "advocate/office/get";
export const EDIT_SINGLE_OFFICE = "advocate/office/edit";

//ADVOCATE APIs
export const ADD_ADVOCATE = "advocate/attorney/add";
export const GET_ADVOCATE = "advocate/attorney/list";
export const GET_ADVOCATE_DETAIL = "advocate/attorney/get";
export const EDIT_ADVOCATE = "advocate/attorney/edit";
export const DELETE_ADVOCATE = "advocate/attorney/delete-advocate";
export const DELETE_ADVOCATE_WITH_EMAIL = "advocate/attorney/delete-request-profile";
export const DELETE_ADVOCATE_WITH_OTP = "advocate/attorney/delete-profile";

//CLIENTE APIs
export const ADD_CLIENTE = "advocate/client/add-client";
export const GET_CLIENTE = "advocate/client/get-client";
export const GET_NOTE_TYPE = "public/get-notes-type";
export const GET_NOTE_LIST = "advocate/note/list";
export const ADD_NOTE = "advocate/note/add";
export const CHANGE_CLIENT_NOTE_STATUS = "advocate/note/update-status";
export const DELETE_CLIENT = "advocate/client/delete-client";
export const GET_ALL_CLIENT = "advocate/client/get-list-client";
// export const GET_ADVOCATE_DETAIL = "advocate/attorney/get";

//TASKS APIs
export const ADD_CATEGORY = "advocate/task/add-category";
export const GET_CATEGORY = "advocate/task/get-Category";
export const GET_CATEGORY_COLOR = "public/get-category-color";
export const ADD_ADVOCATE_TASK = "advocate/task/add-task";
export const GET_ADVOCATE_TASK = "advocate/task/get-task";
export const GET_ADVOCATE_CALENDAR_TASK = "advocate/task/get-undone-task";
export const DONE_ADVOCATE_TASK = "advocate/task/task-status";
export const EDIT_ADVOCATE_TASK = "advocate/task/edit-task";
export const DELETE_ADVOCATE_TASK = "advocate/task/delete-task";
export const ALERT_ADVOCATE_TASK = "advocate/task/set-alert";

//PROCESS APIs
export const SEARCH_PROCESS = "advocate/process/search-status-process";
export const REGISTER_PROCESS = "advocate/process/register-process";
export const GET_PROCESS = "advocate/process/get-list-of-process";
export const ADD_SUBSCRIBER = "advocate/process/add-subscriber";
export const GET_SINGLE_PROCESS = "advocate/process/get-process";
export const GET_PROCESS_TASK = "advocate/task/get-process-task";
export const GET_PROCESS_USER = "advocate/task/get-process-user";
export const GET_PROCESS_GAITS = "advocate/process/get-gaits";
export const ADD_GAITS_RESPONSE = "advocate/process/gait-response";
export const GET_PROSESS_STATUS = "advocate/process/get-process-status";
export const CHANGE_PROSESS_STATUS = "advocate/process/process-status";
export const FILTER_PROCESS = "advocate/process/filter-process";
export const PROCESS_COURT_COST = "advocate/process/court-costs";
export const DELETE_PROCESS = "advocate/process/delete-process";
export const UPLOAD_PROCESS = "advocate/process/upload-xlsx";

// CHAT APIs
export const GET_CHAT_DETAIL = "advocate/chat-message/get-chat-list";
export const GET_ALL_CHAT = "advocate/chat-message/get-message";
export const GET_PERSONAL_CHAT_HISTORY = "advocate/chat-message/get-personal-message";
export const ADD_CHAT_ATTACHMENT = "advocate/chat-message/attachment";
export const GET_CHAT_ATTACHMENT = "advocate/chat-message/get-attachment-list";

// PAYMENT TASK BY ADVOCATE APIs
export const GET_PAYMENTTASK_NAMES = "advocate/paymentTask/get-names";
export const FINANCE_DASHBOARD_DATA = "advocate/paymentTask/finance-web";
export const PAYMENT_TASK_GOALS = "advocate/paymentTask/goals";
export const GET_PAYMENT_TASK_GOALS = "advocate/paymentTask/get-goals";
export const FINANCE_DASHBOARD_TABLE = "advocate/paymentTask/finance-dashboard-list";
export const GET_TRANSACTION_TYPE = "advocate/paymentTask/get-types";
export const GET_TRANSACTION_OFFICES = "advocate/paymentTask/get-offices";
export const ADD_PAYMENT_USER_TYPE = "advocate/paymentTask/add-user-type";
export const ADD_PAYMENT_OFFICE = "advocate/paymentTask/add-office";
export const ADD_PAYMENT_NOTE = "advocate/paymentTask/payment-note";
export const FILTER_PAYMENT_NOTE = "advocate/paymentTask/filter-payment-note";

// CHART PAGE APIs
export const GET_PAYMENT_GRAPHIC_LIST = "advocate/paymentTask/get-graphic-list";
export const GET_PAYMENT_CHART_DATA = "advocate/paymentTask/get-graphic-chart";
export const GET_PAYMENT_CHART_CARD = "advocate/paymentTask/get-graphic-data";

// DASHBOARD APis
export const DASHBOARD_GOALS = "advocate/dashboard/dashboard-data";
export const CAROUSAL_CARD = "advocate/dashboard/home-screen-cards";
export const DELETE_CARD = "advocate/dashboard/remove-card";
export const GET_NOTIFICATION = "advocate/dashboard/get-notification-list";
export const GET_NOTIFICATION_COUNT = "advocate/dashboard/get-notification-count";

// CALENDAR
export const CALENDAR_TASK = "advocate/task/task-month";

// PLAN_DETAILS
export const PLAN_PAYMENT_DETAILS = "advocate/plan/list-of-plan";
export const PLAN_PAYMENT_TABLE = "advocate/plan/all-plan-list";

export const GET_REGISTER_OFFICE = "public/get-office-type";
export const TRIAL_PLAN = "advocate/plan/subscribe-trial-plan";
export const SUBSCRIBE_PLAN = "advocate/plan/subscribe-plan";
