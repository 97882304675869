import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Card, CardContent, Container, FormControl, MenuItem, Select, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const useStyles = makeStyles(() => ({
  formControl: {
    "& .MuiInputBase-root": {
      color: "#000",
      display: "flex",
      // borderColor: "#6EC177",
      // borderWidth: "1px",
      // borderStyle: "solid",
      // borderRadius: "100px",
      minWidth: "120px",
      justifyContent: "center",
    },
    "& .MuiSelect-select.MuiSelect-select": {
      paddingRight: "0px",
      display: "flex",
    },
  },
  select: {
    width: "100px",
    fontSize: "12px",
    "&:focus": {
      backgroundColor: "transparent",
    },
  },
  selectIcon: {
    position: "relative",
    color: "#bb86fc",
    fontSize: "14px",
  },
  paper: {
    borderRadius: 12,
    marginTop: 2,
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
    "& li": {
      fontWeight: 200,
      // paddingTop: 8,
      paddingBottom: 8,
      fontSize: "12px",
    },
    "& li.Mui-selected": {
      color: "white",
      background: "#fff",
    },
    "& li.Mui-selected:hover": {
      background: "#fff",
    },
  },
}));

const DropDown = ({ dropDownValue, statusProcess, value, handleChange }) => {
  const classes = useStyles();
  const menuProps = {
    classes: {
      list: classes.list,
      paper: classes.paper,
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "center",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "center",
    },
    getContentAnchorEl: null,
  };
  return (
    <div>
      <FormControl variant="standard">
        <Select value={value} onChange={handleChange} disableUnderline IconComponent={ExpandMoreIcon} MenuProps={menuProps}>
          {statusProcess?.map(item => (
            <MenuItem key={item.value} value={item.value} sx={{ display: "flex !important", justifyContent: "space-between" }}>
              <Typography sx={{ color: item.label === "Despesas" ? "#5031A9" : "#FF5A01" }} variant="body1">
                {item.label}
              </Typography>
            </MenuItem>
          ))}
          {/* <MenuItem sx={{ display: "flex !important", justifyContent: "space-between" }}>expense</MenuItem>
            <MenuItem sx={{ display: "flex !important", justifyContent: "space-between" }}>Income</MenuItem> */}
        </Select>
      </FormControl>
    </div>
  );
};

export default DropDown;
