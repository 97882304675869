import { Routes, Route, Navigate } from "react-router-dom";
import "./App.css";
// import "bootstrap/dist/css/bootstrap.min.css";
import LoginPage from "./pages/LoginPage";
import HomePage from "./pages/Home";
import { ProfilePage } from "./pages/Profile";
import { SettingsPage } from "./pages/Settings";
import { ProtectedLayout } from "./components/ProtectedLayout";
import { HomeLayout } from "./components/HomeLayout";
import ForgotPassword from "./pages/ForgotPassword";
import DadosAssinatura from "./pages/DadosAssinatura";

import ChatPage from "./pages/ChatPage";
import ChatDescription from "./components/ChatDescription";

import Clients from "./pages/Clients";
// import "./styles.css";
import Checkout from "./pages/Checkout";
import ProcessosPage from "./pages/ProcessosPage";
import ProcessoDetail from "./pages/ProcessoDetail";
import Andamentos from "./pages/Andamentos";
import Information from "./pages/Information";
import Office from "./pages/Office";
import Cliente from "./components/Clients/Cliente";
import AdvogadosProfile from "./pages/AdvogadosProfile";
import AdicionarProcesso from "./pages/AdicionarProcesso";
import AdicionarCliente from "./pages/AdicionarCliente";
import AdicionarAdvogado from "./pages/AdicionarAdvogado";
import AdicionarNota from "./pages/AdicionarNota";
import Alertas from "./pages/Alertas";
import CriarTarefa from "./pages/CriarTarefa";
import TaskList from "./pages/TaskList";
import Financeiro from "./pages/financeiro";
import FinanceiroAdicionar from "./pages/financeiroAdicionar";
import Despesas from "./pages/Despesas";
import FinanceiroDespesasChart from "./components/Charts/FinanceiroDespesasChart";
import VerifyAndUpdatePassword from "./pages/VerifyAndUpdatePassword";
import Loader from "./components/Loader";
import Example from "./components/Carousal/CarousalCard";
import socketIO from "socket.io-client";
import { SOCKET_URL } from "./config";
import * as profileAction from "./redux/profile/action";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import ErrorPage from "./pages/ErrorPage";
import Error from "./components/Error/Error";
import RegisterPage from "./pages/RegisterPage";
import PlanCards from "./components/PlanCards";
import Plan from "./pages/Plan";
import OtpPage from "./components/RegisterAdvocate/Otp";
const App = ({ getProfile, loginData, permission, planPermission }) => {
  const socket = socketIO.connect(SOCKET_URL);

  const [profile, setProfile] = useState(null);

  // useEffect(() => {
  //   if (loginData) {
  //     getProfile().then(res => {
  //       console.log("res :: profile", res);
  //     });
  //   }
  // }, []);
  // console.log("permission :::", permission);
  // console.log("planPermission :::", planPermission);

  return (
    <Routes>
      <Route element={<HomeLayout />}>
        <Route path="/" element={<LoginPage />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/verify-password" element={<VerifyAndUpdatePassword />} />
        {/* <Route path="/error" element={<ErrorPage />} /> */}
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/otp" element={<OtpPage />} />
      </Route>
      <Route path="/plan" element={<Plan />} />

      <Route path="/home" element={<ProtectedLayout />}>
        <Route path="error" element={<ErrorPage />} />

        <Route path="*" element={<Navigate to="error" replace />} />
        <Route path="" element={<HomePage />} />
        <Route path="profile" element={<ProfilePage />} />
        <Route path="settings" element={<SettingsPage />} />
        {planPermission?.isSubscribed || planPermission?.isTrial ? (
          <Route path="dados" element={<DadosAssinatura />} />
        ) : (
          <Route path="error" element={<ErrorPage />} />
        )}
        <Route path="chats" element={<ChatPage />} />
        <Route path="chat-file/:id" element={<ChatDescription />} />
        <Route path="checkout" element={<Checkout />} />
        <Route path="clients" element={<Clients />} />
        <Route path="processos" element={<ProcessosPage />}>
          <Route path=":id" element={<ProcessosPage />} />
        </Route>
        <Route path="process-detail" element={<ProcessoDetail />} />
        <Route path="gaits" element={<Andamentos socket={socket} />} />
        {permission?.isOwner ? (
          <>
            <Route path="information" element={<Information />}>
              <Route path=":id" element={<Information />} />
            </Route>
          </>
        ) : (
          <Route path="error" element={<ErrorPage />} />
        )}
        <Route path="office" element={<Office />} />
        <Route path="cliente" element={<Cliente />}>
          {permission?.isOwner && permission?.clientMgt ? (
            <>
              <Route path=":id" element={<Cliente />} />
              <Route path="add" element={<Cliente />} />
            </>
          ) : (
            <Route path="error" element={<ErrorPage />} />
          )}
        </Route>
        <Route path="advogados-profile" element={<AdvogadosProfile />} />
        {permission?.isOwner && permission?.teamMgt ? (
          <>
            <Route path="adicionar-advogado" element={<AdicionarAdvogado />}>
              <Route path=":id" element={<AdicionarAdvogado />} />
            </Route>
          </>
        ) : (
          <Route path="error" element={<ErrorPage />} />
        )}
        <Route path="adicionar-processo" element={<AdicionarProcesso />} />
        <Route path="adicionar-cliente" element={<AdicionarCliente />} />

        <Route path="adicionar-nota" element={<AdicionarNota />} />
        <Route path="alertas" element={<Alertas />} />
        <Route path="Tarefas-semana" element={<TaskList />} />
        <Route path="criar-tarefa" element={<CriarTarefa />}>
          <Route path=":id" element={<CriarTarefa />} />
        </Route>
        <Route path="financeiro" element={<Financeiro />} />
        {permission?.isOwner && permission?.financialMgt ? (
          <Route path="financeiro/adicionar" element={<FinanceiroAdicionar />} />
        ) : (
          <Route path="error" element={<ErrorPage />} />
        )}

        {/* <Route path="financeiro/despesas" element={<Despesas />} /> */}
      </Route>
    </Routes>
  );
};

const mapDispatchToProp = dispatch => {
  return {
    getProfile: () => dispatch(profileAction.getUserProfile()),
  };
};
const mapStateToProps = state => {
  // console.log("state", state);
  return {
    loginData: state?.loginUsers?.isLoggedIn,
    permission: state?.getProfileReducer?.profile,
    planPermission: state?.getProfileReducer?.allData?.user,
  };
};

export default connect(mapStateToProps, mapDispatchToProp)(App);
