import { REGISTER_REQ_DATA_OBJECT, REGISTER_PROCESSING } from "./type";

const defaultReducer = {
  userInfo: null,
  processing: false,
};

const registerReducer = (state = defaultReducer, action) => {
  switch (action.type) {
    case REGISTER_REQ_DATA_OBJECT:
      return {
        ...state,
        processing: false,

        userInfo: action.payload,
      };

    case REGISTER_PROCESSING:
      return {
        ...state,
        processing: true,
      };

    // case STOP_ADVOCATE_PROCESSING:
    //   return {
    //     ...state,
    //     processing: false,
    //   };

    default:
      return state;
  }
};

export default registerReducer;
