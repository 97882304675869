import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import { makeStyles } from "@material-ui/core/styles";
import TaskListCard from "../components/Calender/TaskListCard";
import PlusBottom from "../components/PlusBottom";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import { ToggleButton, Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { getAdvocateTask } from "../helper/API/task";
import Loader from "../components/Loader";
import RecordFound from "../components/RecordFound/RecordFound";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { CoPresentOutlined } from "@mui/icons-material";
import { toast } from "react-toastify";
const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    // borderRadius: 160,66
    // maxWidth: 500,
    // "& .MuiButtonBase-root": {
    //     background: "red"
    // },
    "& .MuiGrid-root": {
      "& .MuiFormControl-root": {
        "& .MuiFormLabel-root": {
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: 600,
          fontSize: "16px",
          lineHeight: "24px",
          color: "#333333",
        },
      },
    },
  },

  card: {
    // marginBottom: "1rem",
    boxShadow: "unset !important",
    borderRadius: "14px",
  },
});

const TaskList = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const date = new Date();
  const currentDate = moment().format("YYYY-MM-DD");
  const [alignment, setAlignment] = useState(currentDate);
  const [loading, setLoading] = useState(false);
  const [taskList, setTaskList] = useState([]);
  const [refresh, setRefresh] = useState(0);
  const [state, setstate] = useState(0);

  useEffect(() => {
    setLoading(true);
    const submitData = { date: alignment };
    getAdvocateTask(submitData).then(res => {
      if (res.success) {
        setTaskList(res.data.getTask);
        setLoading(false);
      } else {
        setLoading(false);
        // toast.error(res.message);
      }
    });
  }, [alignment, refresh]);

  const handleClick = () => {
    navigate("/home/criar-tarefa");
  };

  const translate = day => {
    // console.log("data", day);
    if (day == "Sun") {
      return "Dom";
    } else if (day == "Mon") {
      return "Seg";
    } else if (day == "Tue") {
      return "Ter";
    } else if (day == "Wed") {
      return "Qua";
    } else if (day == "Thu") {
      return "Qui";
    } else if (day == "Fri") {
      return "Sex";
    } else if (day == "Sat") {
      return "Sáb";
    }
  };

  const handleButtonChange = (event, val) => {
    setAlignment(val);
  };
  var weekDays = [];

  function getWeekFromStartDay(start) {
    var curr = new Date(); // get current date
    var first = curr.getDate() - curr.getDay() + start;

    for (let i = first; i < first + 7; i++) {
      let day = new Date(new Date().setDate(i)).toISOString().slice(0, 10);
      weekDays.push({
        date: moment(day).format("DD"),
        days: translate(moment(day).format("ddd")),
        fullDate: moment(day).format("YYYY-MM-DD"),
      });
    }
    return weekDays;
  }

  getWeekFromStartDay(state);

  const handleAddDays = () => {
    setstate(state + 7);
  };
  const handleSubtractDays = () => {
    setstate(state - 7);
  };

  return (
    <div>
      <Box className={classes.root} sx={{ flexGrow: 1, background: "#F8F5FA", height: "100vh" }} px={8} py={4}>
        <PlusBottom url="" handleClick={handleClick} />
        <div style={{ display: "flex", justifyContent: "space-around", alignItems: "center" }}>
          <div onClick={handleSubtractDays} style={{ display: "flex", justifyContent: "center", width: "50px", cursor: "pointer" }}>
            <ArrowBackIosIcon />
          </div>

          {weekDays.map((obj, indx) => (
            <ToggleButton
              key={indx}
              onChange={handleButtonChange}
              value={obj.fullDate}
              style={{
                borderRadius: "11px",
                backgroundColor: alignment === obj.fullDate ? "#8001FF" : "#fff",
                color: alignment === obj.fullDate ? "#fff" : "#000",
                display: "block",
                margin: 5,
                padding: "2rem 3rem",
              }}>
              <div className={classes.date} style={{ color: alignment === obj.fullDate ? "#fff" : "#000" }}>
                {obj.date}
              </div>
              <br />
              <div className={classes.day} style={{ fontWeight: 600, color: alignment === obj.fullDate ? "#fff" : "#000" }}>
                {obj.days}
              </div>
            </ToggleButton>
          ))}
          <div onClick={handleAddDays} style={{ display: "flex", justifyContent: "center", width: "50px", cursor: "pointer" }}>
            <ArrowForwardIosIcon />
          </div>
        </div>
        {loading ? (
          <Loader />
        ) : (
          <>
            {taskList.length ? (
              taskList.map((obj, indx) => (
                // <Link to={`/home/criar-tarefa/${obj.id}`} style={{ textDecoration: "none" }} state={{ task: obj }}>
                <TaskListCard
                  backgroundColor={"#8001FF"}
                  data={obj}
                  src={"/assets/images/check-circle-blue.png"}
                  refresh={refresh}
                  setRefresh={setRefresh}
                />
                // </Link>
              ))
            ) : (
              <RecordFound label={"Nenhum registro foi encontrado."} />
            )}
          </>
        )}
      </Box>
    </div>
  );
};

export default TaskList;
