import { Box, Container } from "@mui/material";
import React from "react";

const ShowMobileAppScreen = () => {
  return (
    <div>
      <Container component="main" maxWidth="md" className="registerContainer" style={{ display: "flex", alignItems: "center" }}>
        <Box width={"100%"}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <img alt="complex" src="/assets/images/inova_juris_logo.png" sx={{ height: 150 }} />
          </Box>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <h4 style={{ margin: "0px", textAlign: "center" }}>Para uma melhor experiência use nosso aplicativo móvel.</h4>
          </div>
          <div style={{ display: "flex", justifyContent: "center", marginTop: 20 }}>
            <a class="button-link" href="https://apps.apple.com/us/app/inova-juris-cliente/id1661972827" target="_blank">
              <img src="https://inova-juris-test-file-server.s3.sa-east-1.amazonaws.com/assets/appstore_buttons.png" />
            </a>
            <a class="button-link" href="https://play.google.com/store/apps/details?id=com.inova.juris.client" target="_blank">
              <img src="https://inova-juris-test-file-server.s3.sa-east-1.amazonaws.com/assets/playstore_buttons.png" />
            </a>
          </div>
        </Box>
      </Container>
    </div>
  );
};

export default ShowMobileAppScreen;
