import { LOGIN_USER_SUCCESSFUL, LOGIN_PROCESSING, STOP_LOGIN_PROCESSING, LOGOUT_SUCCESSFUL, LOGIN_USER_LOCAL_DATA } from "./type";

const defaultReducer = {
  isLoggedIn: false,
  processing: false,
  loginUser: null,
  loginData: null,
  data: null,
};

const loginReducer = (state = defaultReducer, action) => {
  switch (action.type) {
    case LOGIN_USER_SUCCESSFUL:
      return {
        ...state,
        processing: false,
        isLoggedIn: true,
        loginUser: action.payload,
      };

    case LOGIN_PROCESSING:
      return {
        ...state,
        processing: true,
        isLoggedIn: false,
      };
    case LOGIN_USER_LOCAL_DATA:
      return {
        ...state,
        data: action.payload,
      };

    case STOP_LOGIN_PROCESSING:
      return {
        ...state,
        processing: false,
        isLoggedIn: false,
      };

    case LOGOUT_SUCCESSFUL: {
      return {
        ...state,
        ...defaultReducer,
      };
    }

    default:
      return state;
  }
};

export default loginReducer;
