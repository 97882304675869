import React from 'react'
import { Avatar, AvatarGroup, Box, Button, Card, CardContent, Typography } from '@mui/material'
import { experimentalStyled as styled } from "@mui/material/styles";
import { makeStyles } from "@material-ui/core/styles";
import CustomizedSearchBar from '../components/Inputs/SearchBar';

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
        borderRadius: 160,
        // maxWidth: 500,
        "& .MuiButtonBase-root": {
            background: "red"
        }

    },

    card: {
        // marginBottom: "1rem",
        boxShadow: "unset !important",
        borderRadius: "14px",

    },
    title: {
        fontFamily: 'Roboto',
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "14px",
        lineHeight: "18px",
        paddingRight: 5,
        color: " #000000",
    },
    desc: {
        fontFamily: 'Roboto',
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "18px",
        paddingRight: 5,
        color: " #000000",
    }
});

const AdicionarProcesso = () => {
    const classes = useStyles()
    return (
        <div>
            <Box sx={{ flexGrow: 1, background: "#F8F5FA" }} px={8} py={4}>
                <CustomizedSearchBar placeholder={"Informe o número (CNJ)"} />
                <Card variant="elevation" className={classes.card} sx={{ background: "#fff", marginBottom: "1rem", borderRadius: "13px", paddingX: 2, }} >
                    <CardContent sx={{ position: "relative" }}>
                        <Box>
                            <Typography mb={2}><span className={classes.title}>Número:</span><span className={classes.desc}>10285669-25.2022.8.26.0007</span>  </Typography>
                            <Typography ><span className={classes.title}>Status:</span><span className={classes.desc}>Consulta em andamento</span>  </Typography>
                        </Box>
                    </CardContent>
                </Card>
            </Box>
        </div>
    )
}

export default AdicionarProcesso