import React, { useEffect, useState } from "react";
import { experimentalStyled as styled } from "@mui/material/styles";
import { Link, useNavigate } from "react-router-dom";
import { Box, Card, CardContent } from "@mui/material";
import useStyles from "../../asset/css/clients";
import { clientes } from "./data";
import { getAllClient, getClient } from "../../helper/API/cliente";
import Loader from "../Loader";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteClientModal from "./DeleteClientModal";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import SearchInput from "../SearchInput";
import * as clientAction from "../../redux/clientList/action";
import { connect } from "react-redux";
import { toast } from "react-toastify";

const Img = styled("img")({
  display: "block",
  //   maxWidth: "40%",
  //   maxHeight: "100%",
});

const ClientsList = ({ clientListData, clientListFromRedux, processing }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [clientData, setClientData] = useState(clientListFromRedux?.clientList);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [refresh, setRefresh] = useState(0);
  const [searchVal, setSearchVal] = useState("");

  // console.log("clientListData", clientListData);
  // console.log("clientListFromRedux", clientListFromRedux?.clientList);
  // console.log("processing", processing);

  useEffect(() => {
    clientListData();
    getAllClient().then(res => {
      if (res.success) {
        setLoading(false);
        setClientData(res.data.findClient);
        // console.log("res list form:::", res);
      } else {
        toast.error(res.message);
        setLoading(false);
      }
    });
    // clientListData();
  }, [refresh]);

  const handleDeleteClient = id => {
    setOpen(true);
    setDeleteId(id);
  };

  const handleChatClient = id => {
    // console.log("id personal advocate", id);
    localStorage.setItem("senderId", id);
    navigate(`/home/chat-file/${id}`);
  };

  const onSearchClient = () => {
    setSearchVal("");
    setClientData(clientListFromRedux?.clientList);
  };

  const onChangeSearch = event => {
    setSearchVal(event.target.value);
    if (event.target.value) {
      var updateState = clientData.filter(({ name }) => name.toLowerCase().includes(event.target.value.toLowerCase()));
      setClientData(updateState);
      updateState = [];
      // console.log("obj if 1");
    } else if (event.target.value == "") {
      setClientData(clientListFromRedux?.clientList);
      // console.log("obj if 2");
    } else {
      setClientData([]);
      // console.log("obj if 3");
    }
  };

  return (
    <>
      <SearchInput placeholder={"Buscar cliente"} onChange={onChangeSearch} searchVal={searchVal} onClick={onSearchClient} />
      {loading ? (
        <Loader />
      ) : (
        <div className={classes.root}>
          {clientData.map(obj => (
            <Card variant="elevation" className={classes.card} key={obj.id}>
              <CardContent sx={{ padding: "0 !important" }}>
                <Box p={1} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                  <Box p={1}>
                    <Link to={obj.id ? `/home/cliente/${obj.id}` : `/home/cliente`} key={obj.id} state={{ clientInfo: obj }}>
                      <Img
                        alt="profile"
                        src="/assets/images/profile.png"
                        sx={{
                          maxWidth: "50px !important",
                          maxHeight: "50px !important",
                        }}
                      />
                    </Link>
                  </Box>
                  <Box flex={1} p={1}>
                    <p className={classes.title} p={0}>
                      {obj.name}
                    </p>
                    <p className={classes.subtitle}>{obj.email}</p>
                  </Box>
                  <Box p={0}>
                    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                      {/* <div
                        style={{
                          posotion: "relative",
                          padding: "4px 10px",
                          background: "#F3F4F9",
                          borderTopRightRadius: "13px",
                          borderBottomRightRadius: "13px",
                        }}>
                        <Img
                          alt="Delete"
                          src="/assets/images/delete.png"
                          sx={{
                            maxWidth: "20px !important",
                            maxHeight: "20px !important",
                            posotion: "absolute",
                            background: "#fff",
                            padding: "9px",
                            borderRadius: "13px",
                          }}
                        />
                      </div>
                      <div style={{ padding: "4px 15px" }}>
                        <Img
                          alt="Chat"
                          src="/assets/images/chat_msg.png"
                          sx={{ maxWidth: "22px !important", maxHeight: "22px !important" }}
                        />
                      </div> */}
                      {obj?.isRegister && (
                        <ChatBubbleOutlineIcon
                          width={50}
                          height={50}
                          sx={{
                            maxWidth: "20px !important",
                            maxHeight: "20px !important",
                            posotion: "absolute",
                            background: "#fff",
                            color: "#734699",
                            padding: "12px",
                            borderRadius: "11px",
                            cursor: "pointer",
                            border: "1px solid #734699",
                            mr: 2,
                          }}
                          onClick={() => handleChatClient(obj.id)}
                        />
                      )}
                      <DeleteIcon
                        width={50}
                        height={50}
                        sx={{
                          maxWidth: "20px !important",
                          maxHeight: "20px !important",
                          posotion: "absolute",
                          background: "#fff",
                          color: "#734699",
                          padding: "12px",
                          borderRadius: "11px",
                          cursor: "pointer",
                          border: "1px solid #734699",
                          mr: 2,
                        }}
                        onClick={() => handleDeleteClient(obj.connectionId)}
                      />
                    </Box>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          ))}
          {open && (
            <DeleteClientModal
              open={open}
              handleClose={() => setOpen(false)}
              refresh={refresh}
              setRefresh={setRefresh}
              deleteId={deleteId}
            />
          )}
        </div>
      )}
    </>
  );
};

const mapDispatchToProp = dispatch => {
  return {
    clientListData: () => dispatch(clientAction.clientList()),
  };
};
const mapStateToProp = state => {
  // console.log("state", state);
  return {
    clientListFromRedux: state?.clientListReducer,
    processing: state?.clientListReducer?.processing,
  };
};

export default connect(mapStateToProp, mapDispatchToProp)(ClientsList);
