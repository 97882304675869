import * as React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { InputLabel, Typography } from "@mui/material";

const useStyles = makeStyles(() => ({
  formControl: {
    "& .MuiInputBase-root": {
      color: "#000",
      display: "flex",
      // borderColor: "#6EC177",
      // borderWidth: "1px",
      // borderStyle: "solid",
      // borderRadius: "100px",
      minWidth: "120px",
      justifyContent: "center",
      margin: "-28px",
    },
    "& .MuiSelect-select.MuiSelect-select": {
      paddingRight: "0px",
      display: "flex",
    },
  },
  select: {
    width: "120px",
    fontSize: "12px",
    "&:focus": {
      backgroundColor: "transparent",
    },
  },
  selectIcon: {
    position: "relative",
    color: "#bb86fc",
    fontSize: "14px",
  },
  paper: {
    borderRadius: 12,
    // marginTop: 2,
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
    "& li": {
      fontWeight: 200,
      // paddingTop: 8,
      paddingBottom: 8,
      fontSize: "12px",
    },
    "& li.Mui-selected": {
      color: "white",
      background: "#fff",
    },
    "& li.Mui-selected:hover": {
      background: "#fff",
    },
  },
}));

const DropdownButton = ({
  selectVal,

  dropDownValue,
  handleSelectChange,
  statusProcess,
  setStatusId,
  value,
  handleChange,
  items,
}) => {
  const classes = useStyles();
  // console.log("dropDownValue", dropDownValue);
  // console.log("statusProcess", statusProcess);
  const menuProps = {
    classes: {
      list: classes.list,
      paper: classes.paper,
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "center",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "center",
    },
    getContentAnchorEl: null,
  };
  return (
    <div>
      <FormControl className={classes.formControl}>
        {value === "" && <InputLabel htmlFor="demo-controlled-open-select">Status</InputLabel>}
        <Select
          defaultValue={value}
          onChange={handleChange}
          disableUnderline={value === "" ? false : true}
          IconComponent={ExpandMoreIcon}
          MenuProps={menuProps}
          inputProps={{
            name: "age",
            id: "demo-controlled-open-select",
            "data-testid": "select-input",
          }}
          classes={{
            select: classes.select,
            icon: classes.selectIcon,
          }}>
          {statusProcess?.map(item => (
            <MenuItem key={item.id} value={item.id} sx={{ display: "flex !important", justifyContent: "space-between" }}>
              <div style={{ width: "25px", marginRight: 8 }}>
                {item.id === value && <TaskAltIcon sx={{ color: "#bb86fc !important" }} />}
              </div>
              <Typography sx={{ color: "#5E6C93" }} variant="body1">
                {item.label}
              </Typography>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default DropdownButton;
