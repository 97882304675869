const config = {
  TIMEZONE: "America/Sao_Paulo",
};

const envData = (ENV, local, test, production) => {
  if (ENV === "local") {
    return local;
  } else if (ENV === "test") {
    return test;
  } else if (ENV === "production") {
    return production;
  } else {
    console.log(new Error("Something went wrong with credentials"));
  }
};

export const ENV_TYPE = "test"; // local // test // production

export const BASE_URL = envData(
  ENV_TYPE,
  "http://localhost:3006/api/v1/",
  "https://testapi.inovajuris.com.br/api/v1/",
  "https://api.inovajuris.com.br/api/v1/",
);

export const SOCKET_URL = envData(
  ENV_TYPE,
  "http://localhost:3006",
  "https://testapi.inovajuris.com.br",
  "https://api.inovajuris.com.br",
);
export const CONTRY_CODE = envData(ENV_TYPE, "+91", "+55", "+55");
export const CLIENT_ID = envData(
  ENV_TYPE,
  "680552504511-1k98moqnnir7lbkf25ck36pu0a11ip1g.apps.googleusercontent.com",
  "680552504511-1k98moqnnir7lbkf25ck36pu0a11ip1g.apps.googleusercontent.com",
  "680552504511-1k98moqnnir7lbkf25ck36pu0a11ip1g.apps.googleusercontent.com",
);
export const APPLE_CLIENT_ID = envData(
  ENV_TYPE,
  "br.com.inovajuris.testadvocacia",
  "br.com.inovajuris.testadvocacia",
  "br.com.inovajuris.advocacia",
);
export const APPLE_REDIRECT_URI = envData(
  ENV_TYPE,
  "https://b346-43-254-176-209.ngrok-free.app/apple-auth-response",
  "br.com.inovajuris.testadvocacia",
  "br.com.inovajuris.advocacia",
);
// console.log("SOCKET_URL", SOCKET_URL);

export const STRIPE_PUBLISHABLE_KEY = envData(
  ENV_TYPE,
  'pk_test_51LuRUWKNwI06INui8pi4tOPOeCKj9nFyTeQ04AYuEPjL1ujQ9Gqnt3QMZX05ofBbey2C2bKS7suse1HBBD7ux95l006ojdkp8r',
  'pk_test_51LuRUWKNwI06INui8pi4tOPOeCKj9nFyTeQ04AYuEPjL1ujQ9Gqnt3QMZX05ofBbey2C2bKS7suse1HBBD7ux95l006ojdkp8r',
  'pk_live_51LuRUWKNwI06INuiGRRf1IALEAoBXRD0qqwiODse8SOrOehQSiKseRe9jI0947C7oRTzmwCSH4vikIQVzHAlj92P00YFohyzCj');

export default config;
