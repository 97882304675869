import React from "react";
import { Card, CardContent, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { experimentalStyled as styled } from "@mui/material/styles";
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";
import { getProcess, registerProcess } from "../../helper/API/process";
import { toast } from "react-toastify";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import { connect } from "react-redux";

const Img = styled("img")({
  display: "block",
  //   maxWidth: "40%",
  //   maxHeight: "100%",
});

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    borderRadius: 160,
    // maxWidth: 500,
    "& .MuiButtonBase-root": {
      background: "red",
    },
  },

  card: {
    // marginBottom: "1rem",
    boxShadow: "unset !important",
    borderRadius: "14px",
  },
});

const RegisteredProcessesCard = ({ taskList, setProcessList, setLoading, setRefresh, refresh, userData, handleDelteProcess }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleProcessos = () => {
    navigate("/home/process-detail", { state: { taskList } });
  };

  // console.log("taskList component ::", taskList);

  const registerAdvocateProcess = code => {
    setLoading(true);
    const submitData = { codProcesso: code.toString() };
    registerProcess(submitData).then(res => {
      if (res.success) {
        toast.success(res.message);
        setRefresh(refresh + 1);
      } else {
        toast.error(res.message);
        setLoading(false);
      }
    });
  };

  return (
    <div>
      <Card
        variant="elevation"
        className={classes.card}
        sx={{
          background: "rgba(236, 163, 177, 0.09)",
          marginBottom: "1rem",
          borderRadius: "30px",
          cursor: taskList?.isRegister ? "pointer" : "",
        }}>
        <CardContent>
          <Box p={1} display={"flex"} justifyContent={"space-between"} alignItems={""}>
            <Box
              onClick={taskList?.isRegister ? handleProcessos : null}
              sx={{ backgroundColor: "#fff", border: "2px solid #fff", p: 2, borderRadius: "50%", height: "30px" }}>
              <Img
                alt="profile"
                src="/assets/images/ragister.png"
                sx={{
                  maxWidth: "60px !important",
                  maxHeight: "60px !important",
                }}
              />
            </Box>
            <Box onClick={taskList?.isRegister ? handleProcessos : null} flex={1} ml={1}>
              {taskList.titulo && (
                <Typography>
                  <span style={{ fontWeight: 600 }}>Título: </span>
                  <span>{taskList.titulo} </span>
                </Typography>
              )}
              {taskList.numProcesso && (
                <Typography>
                  <span style={{ fontWeight: 600 }}>Número: </span>
                  <span>{taskList.numProcesso} </span>
                </Typography>
              )}
              <Typography>
                {taskList.tribunal && (
                  <>
                    <span style={{ fontWeight: 600 }}>Tribunal: </span>
                    <span>{taskList.tribunal} </span>
                  </>
                )}
              </Typography>
              {taskList.Advogado && (
                <Typography>
                  <span style={{ fontWeight: 600 }}>Advogado (a): </span>
                  <span>{taskList.Advogado}</span>
                </Typography>
              )}
              {taskList.Cliente && (
                <>
                  <Typography>
                    <span style={{ fontWeight: 600 }}>Cliente: </span>
                    <span>{taskList.Cliente}</span>
                  </Typography>
                </>
              )}

              <Typography>
                {(taskList.autor || taskList.author) ? (
                  <>
                    <span style={{ fontWeight: 600 }}>Autor (a): </span>
                    {
                      taskList.autor ? (
                        <span>{taskList.autor.map((val) => {
                          return <>{val.nome}<br /></>
                        })} </span>
                      ) : null
                    }
                    {
                      taskList.author ? (
                        <span>{taskList.author.map((val) => {
                          return <>{val}<br /></>
                        })} </span>
                      )
                        : (null)
                    }
                  </>
                ) : null}
              </Typography>

              <Typography>
                {(taskList.reu || taskList.defendant) ? (
                  <>
                    <span style={{ fontWeight: 600 }}>Réu: </span>
                    <span>{
                      taskList.reu ? taskList.reu.map((val) => {
                        return <>{val.nome}<br /></>
                      }) : null
                    }
                      {taskList.defendant ? taskList.defendant.map((val) => {
                        return <>{val}<br /></>
                      }) : null}
                    </span>
                  </>
                ) : null}
              </Typography>

              <Typography>
                {taskList.status && (
                  <>
                    <span style={{ fontWeight: 600 }}>Status: </span>
                    <span>{taskList.status} &nbsp;</span>
                  </>
                )}
                {taskList.Andamentos && (
                  <>
                    <span style={{ fontWeight: 600 }}>Andamentos: </span>
                    <span>{taskList.Andamentos} </span>
                  </>
                )}
              </Typography>
              <Typography>
                {taskList.instancia && (
                  <>
                    <span style={{ fontWeight: 600 }}>Instancia: </span>
                    <span>{taskList.instancia} </span>
                  </>
                )}
              </Typography>
            </Box>
            <Box p={0} sx={{ position: "relative", zIndex: 500 }}>
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                cursor={"pointer"}
                alignItems={"center"}
                className={classes.actionContainer}>
                {!taskList.isRegister && userData.isOwner && (
                  <div
                    style={{
                      posotion: "relative",
                      padding: "5px 15px",

                      borderTopRightRadius: "13px",
                      borderBottomRightRadius: "13px",
                    }}
                    onClick={() => registerAdvocateProcess(taskList.codProcesso)}>
                    <NoteAddIcon
                      width={50}
                      height={50}
                      sx={{
                        maxWidth: "30px !important",
                        maxHeight: "30px !important",
                        posotion: "absolute",
                        background: "#fff",
                        cursor: "pointer",
                        padding: "12px",
                        borderRadius: "13px",
                      }}
                    />
                  </div>
                )}
                {taskList.isRegister && userData.isOwner && (
                  <div
                    style={{
                      posotion: "relative",
                      padding: "5px 15px",

                      borderTopRightRadius: "13px",
                      borderBottomRightRadius: "13px",
                    }}
                    onClick={() => handleDelteProcess(taskList.id)}>
                    <Img
                      alt="Delete"
                      src="/assets/images/registerDelete.png"
                      width={50}
                      height={50}
                      sx={{
                        maxWidth: "30px !important",
                        maxHeight: "30px !important",
                        posotion: "absolute",
                        background: "#fff",
                        color: "red",
                        padding: "12px",
                        borderRadius: "13px",
                      }}
                    />
                  </div>
                )}
              </Box>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
};

const mapStateToProp = state => {
  // console.log("state", state);
  return {
    userData: state?.loginUsers?.loginUser?.user,
  };
};

export default connect(mapStateToProp)(RegisteredProcessesCard);
